export default {
    "pdfsign": {
        "Error!Unexpected": "Ein unerwarteter Fehler ist aufgetreten. Versuchen Sie es später noch einmal.",
        "SelectSigner": "Unterzeichner auswählen",
        "SignatureTimestampFormat": "Unterzeichnet am dd.mm.yyyy",
        "DeclinationPlaceHolder": "Gründe für die Ablehnung (optional)",
        "SignerGreetings": "Hallo ",
        "WhoSigns": "Wer unterzeichnet:",
        "Sign": "Unterzeichnen",
        "WhatSigned": "Was wird unterzeichnet: <span class='sh-nowOrNeverText'>(Prüfen Sie das Dokument noch einmal, spätere Änderungen sind nicht mehr möglich.)</span>",
        "SignConfirmationMsg": "Ich habe das/die Dokument(e) geprüft und habe ein gesetzliches Unterschriftsrecht.",
        "UsagePolicyInfoMsg": "Durch die Nutzung von SignHero akzeptieren Sie unsere <a href='terms-of-use.html' target='_blank' style='color: #ff507a;'>Nutzungsbedingungen</a> und <a href='privacy-policy.html' target='_blank' style='color: #ff507a;'>Datenschutzbestimmungen</a>.",
        "Decline": "Ablehnen",
        "Cancel": "Abbrechen",
        "ThankUsingService": "Danke trotzdem!",
        "FindMoreInformation": "Wählen Sie einfach bei <a class='elem-pink' href='https://www.signhero.io'>SignHero.io</a>",
        "SignEventCancelled": "Der Unterzeichnungsprozess wurde abgebrochen",
        "CompleteProcessInfo": "Sie können das/die Dokument(e) nicht unterzeichnen, da diese Unterschriftsanfrage geschlossen wurde. Möglicherweise haben Sie dieses/diese Dokument(e) bereits unterschrieben oder die Unterschriftsanfrage wurde vom Absender storniert.",
        "DeclineToSignTitle": "Unterzeichnung ablehnen",
        "SignedAndSent": "Das/Die Dokument(e) wurden erfolgreich unterzeichnet.",
        "SorryUnexpectedError": "Oh, irgendwas ist schiefgelaufen.",
        "SessionNeedRefreshError": "Ihre Sitzung sollte aktualisiert werden. Bitte versuchen Sie es erneut.",
        "ReasonExpiredSession": "Der Link, dem Sie gefolgt sind, ist möglicherweise defekt oder die Einladung wurde entfernt.",
        "InvalidLoginUrl": "Bitte vergewissern Sie sich, dass die eingegebene URL mit dem Link in Ihrer E-Mail übereinstimmt, und versuchen Sie es erneut.",
        "You": "Sie"
    },
    "signedin": {
        "locale!fi": "Suomi",
        "locale!en": "English",
        "locale!pl": "Polski",
        "locale!de" : "Deutsch",
        "locale!sv" : "Svenska",
        "locale!es" : "Español",
        "locale!fr" : "Français",
        "locale!nl" : "Nederlands",
        "Applications": "Anwendungen",
        "SearchUsers": "Suche nach Benutzern",
        "UserDeleted" : "Der Benutzer wurde deaktiviert und wird nach 30 Tagen endgültig gelöscht",
        "UserRestored" : "Der Benutzer wurde erneut aktiviert",
        "UserAlreadyActive" : "Der Benutzer ist bereits aktiv.",
        "OrganizationHasBeenDeleted": "Ein Administrator hat gerade Ihre Organisation gelöscht.\nWir bedauern Sie gehen zu sehen, und hoffen Sie hier eines Tages wiederzusehen.",
        "SendReminder": "Aktivierungserinnerung senden",
        "EmailReminderSuccess": "E-Mail-Erinnerung gesendet.",
        "AdminStatusChanged": "Benutzer aktualisiert.",
        "DateTimeFormat": "dd.MM.yyyy, HH:mm:ss",
        "SessionType!password": "Passwort",
        "SessionType!auth_code": "Anwendung",
        "Start": "Neu anfangen",
        "TheService": "Administration",
        "Save": "Speichern",
        "MyOrganization": "Meine Organisation",
        "OrganizationName": "Organisationsname",
        "ManageAccounts": "Konten verwalten",
        "MyIntegrations": "Integration",
        "Statistics" : "Statistik",
        "isAdmin": "Admin",
        "DeleteUser": "Benutzerkonto löschen",
        "RestoreAccount": "Benutzerkonto wiederherstellen",
        "Error!Unexpected": "Ein unerwarteter Fehler ist aufgetreten. Versuchen Sie es später nochmal.",
        "Error!InvitedUserExists": "Der Benutzer ist bereits ein Mitglied  dieser Organisation oder gehört zu einer anderen Organisation. Im zweiten Fall wenden Sie sich bitte an ${email}",
        "InviteUser": "Einladen",
        "UserInvited" : "Benutzer eingeladen",
        "Create": "Erstellen",
        "ConfirmDeleteEverything": "Möchten Sie dieses Organisationskonto wirklich löschen (${groupTitle})?\n",
        "ConfirmCancelOngoingProcesses" : "Ihre Organisation hat ${processNum} laufende Unterzeichnungsprozesse. Die sollte man abbrechen bevor man mit dem löschen des Organisationskonto fortfahrt.\n "
                                          + "\nMöchten Sie wirklich alle laufende Prozesse abbrechen und dieses (${groupTitle}) Organisationskonto löschen?\n",
        "IncorrectConfirmationCode" : "Falscher Bestätigungscode.",
        "DownloadOrganizationDataMessage": "Laden Sie Benutzer- und Unterzeichnungsprozessdaten als eine JSON-Datei herunter.",
        "DownloadOrganizationDataLink": "Organisationsdaten herunterladen",
        "Subscription": "Abonnement",
        "InviteUserHeader": "Neuen Benutzer in Ihre Organisation einladen",
        "AccountStatus" : "Kontostatus:",
        "OwnAccount" : "Ihr eigenes Konto",
        "StatusActive" : "Aktiv, der Benutzer hat die Einladung angenommen.",
        "StatusInactive" : "Inaktiv, der Benutzer hat die Einladung noch nicht angenommen.",
        "StatusDeleted" : "Zum Löschen markiert. Das Abonnement ist inaktiv, es wird für diesen Benutzer nicht verlängert.",
        "AccountRights" : "Rechte:",
        "DownloadOrganizationData": "JSON herunterladen",
        "DeleteOrganizationMessage": "Löschen Sie diese Organisation, Ihr Konto und alle anderen Administrator- und Benutzerkonten",
        "DeleteEverything": "Alles löschen",
        "OrganizationHasActiveProcesses" : "Die Organisation konnte nicht gelöscht werden, weil es laufende Unterzeichnungsprozesse oder einen aktiven Antrag auf Zusammenlegung gibt.",
        "MergeOrganizationMessage": "Die Organisation kann die Zusammenlegung mit einer anderen beantragen.",
        "RequestMergeDetails": "Der Administrator der Organisation kann die Zusammenlegung mit einer anderen Organisation beantragen. Der Antrag ist 24 Stunden gültig.\n\n"
            + "Nach dem der Antrag auf Zusammenlegung von einem Administrator der anderen Organisation akzeptiert wurde, werden die Benutzerkonten, die Unterzeichnungsprozesse und die dazu gehörenden Dokumente in die andere Organisation eingefügt und diese Organisation wird aufgelöst. "
            + "Benutzer dieser Organisation werden nach der Zusammenlegung keine Administratorrechte haben. Die gewünschten Rechte müssen vom einem Administrator der neuen Organisation hinzugefügt werden. "
            + "Wenn es ein aktives Abonnement gab, wird die verbleibende Abonnementlaufzeit gekündigt und die Benutzer werden zum Abonnement der neuen Organisation gezählt.",
        "MergeOrganizationDetails": "Die Organisation ${groupName} hat beantragt, das Organisationskonto mit Ihrem Organisationskonto zusammenlegen "
            + "Der Antrag ist 24 Stunden gültig.\n\n"
            + "Nach dem der Antrag auf Zusammenlegung von Ihnen akzeptiert wurde, werden die Benutzerkonten, die Unterzeichnungsprozesse und die dazu gehörenden Dokumente in Ihre Organisation eingefügt und die andere Organisation aufgelöst. "
            + "Benutzer der anderen Organisation werden nach der Zusammenlegung keine Administratorrechte haben. "
            + "Die gewünschten Rechte müssen vom einem Administrator dieser Organisation hinzugefügt werden."
            + "Die eingefügten Benutzer werden zum Abonnement Ihrer Organisation gezählt.",
        "RequestMerge": "Antrag auf Zusammenlegung",
        "ConfirmMergeOrganisation": "Möchten Sie dieses Organisationskonto wirklich mit einem anderen zusammenlegen? Eine Wiederherstellung der ursprünglichen Konten ist nicht möglich.",
        "EnterAdminEmail": "Geben Sie die E-Mail-Adresse eines Administrators der anderen Organisation ein:",
        "EnterPassword": "Um fortzufahren, geben Sie bitte Ihr Passwort ein:",
        "InvalidPassword" : "Ihr Passwort ist nicht richtig. Bitte versuchen Sie es erneut.",
        "MergeRequestSent": "Antrag auf Organisationszusammenlegung gesendet",
        "MergeRequestCanceled": "Antrag auf Organisationszusammenlegung abgebrochen",
        "MergeRequestRejected": "Antrag auf Organisationszusammenlegung abgelehnt",
        "ReceivedMergeRequests": "Antrag auf Zusammenlegung erhalten",
        "SentMergeRequest": "Antrag auf Zusammenlegung gesendet",
        "Recipient": "Empfänger",
        "Reject": "Ablehnen",
        "Merge": "Zusammenlegen",
        "OrganisationMerged": "Zusammenführung gestartet.",
        "UserNotDifferentOrganizationAdmin": "Der Benutzer ist kein Administrator in einer anderen Organisation.",
        "UserNotFound": "Benutzer wurde nicht gefunden.",
        "OrganizationHasActiveMergeRequest": "Die Organisation hat bereits einen Antrag auf Zusammenlegung.",
        "InvalidMergeRequest": "Der Antrag auf Zusammenlegung wurde nicht gefunden oder ist schon abgelaufen.",
        "Complete": "Archiv",
        "Locales": "Sprache",
        "Sessions": "Sitzungen",
        "LogOutAllDevicesMessage": "Wenn Sie möchten, können Sie sich auf allen Geräten von allen Ihren Sitzungen abmelden.",
        "NoApplicationsMessage": "Sie haben noch keiner Anwendung Zugriff gewährt.",
        "Domain" : "Domain",
        "AccessGiven" : "Zugang erteilt am",
        "LastAccess" : "Letzter Zugriff",
        "RemoveAccess": "Zugriff entfernen",
        "Ongoing": "Laufend",
        "MyAccount": "Konto",
        "UserName": "Name",
        "Email": "E-Mail",
        "Logout": "Abmelden",
        "SignOut": "Von allen Sitzungen abmelden",
        "Confirm": "Bestätigen",
        "Cancel": "Abbrechen",
        "Reset": "Zurücksetzen",
        "Profile": "Mein Profil",
        "Organization": "Organisation",
        "Device": "Gerät",
        "Update" : "Speichern",
        "Delete" : "Löschen",
        "AcceptApiTerms" : "Durch die Nutzung der SignHero-API akzeptieren Sie unsere <a href='/terms/api' target='_blank'>API-Bedingungen</a>.",
        "CannotDeleteActiveFlows" : "Ihre Organisation hat laufende Unterzeichnungsprozesse. Sie müssen sie zuerst abbrechen, bevor Sie mit der Löschung des Organisationskontos fortfahren können.",
        "MySubscription" : "Mein Abonnement",
        "FreeTrialLeft" : "Verbleibende Zeit für die kostenlose Testperiode: ",
        "Days" : "Tage.",
        "SubscriptionPeriod" : "Aktuelle Abonnementlaufzeit:",
        "PleaseActivateSubscription" : "Bitte aktivieren Sie Ihr Abonnement.",
        "Tab1" : "Zahlungsmethode",
        "Tab2" : "Rechnungsverlauf",
        "Tab3" : "Information",
        "NoPaymentNotification" : "Hinweis: Sie haben derzeit keine gültige Zahlungsmethode.",
        "AddCreditCard" : "Fügen Sie eine Kreditkarte für die monatliche Abrechnung von SignHero hinzu",
        "CreditCardEmail" : "E-Mail",
        "BillingDate" : "Rechnungsdatum",
        "Amount" : "Betrag",
        "UsersAmount" : "Benutzer",
        "Duration" : "Dauer",
        "ReferNumber" : "Referenznummer",
        "Invoice" : "Rechnung",
        "Action" : "Aktion",
        "LoadMore" : "Mehr laden",
        "SalesChannel" : "Vertriebskanal",
        "ResellerName" : "Wiederverkäufer: ",
        "ResellerContact" : "Kontakt: ",
        "ResellerMaxUsers" : "Max. Benutzer: ",
        "ResellerCurrentInvitedUsers" : "Aktuell eingeladene Benutzer: ",
        "MaxResellerUsersWarning" : "Die Benutzerzahl hat die von Ihrem Wiederverkäufer festgelegte Höchstgrenze (${resellerMaxUsers}) erreicht.",
        "StartOfSubscription" : "Anfangsdatum des Abonnements:",
        "EstimatedBilling" : "Nächste Rechnung am ${nextBillingDate} für ${nextBillingValue}€",
        "SubInactive1" : "Abonnement ist inaktiv",
        "SubInactive2" : ", es wird für diesen gelöschten Benutzer nicht verlängert",
        "isSubscriptionAdmin" : "Abonnement-Admin",
        "CardInfo" : "Karteninformation",
        "NameOnCard" : "Name auf der Karte",
        "SelectCountry" : "Land oder Region",
        "SelectLocale" : "Sprache auswählen",
        "BusinessAccount" : "Geschäftskonto",
        "OnlyBusinessAccountsSupported" : "Für das ausgewählte Land werden nur Geschäftskonten unterstützt.",
        "TaxIdExplanation" : "Bitte wählen Sie Ihre Steuerregion und geben Sie Ihre Umsatzsteuer-Identifikationsnummer ein.",
        "SelectIdType" : "Wählen Sie die  Art der USt - IdNr. aus",
        "TaxId" : "USt - IdNr.",
        "AddCardTooltip1" : "Bitte fügen Sie zuerst gültige Rechnungsinformationen hinzu.",
        "AddCardTooltip2" : "Bitte fügen Sie gültige Kreditkarteninformationen hinzu. Alle Felder sind Pflichtfelder.",
        "AddCardTooltip3" : "Kreditkarteninformationen sind gültig.",
        "BillingInformation" : "Rechnungsinformationen",
        "Edit" : "Aktualisieren",
        "EditBillingInformation" : "Rechnungsinformationen aktualisieren.",
        "AddBillingTooltip" : "Bitte füllen Sie alle Pflichtfelder aus und überprüfen Sie, ob die Steuernummer mit den Länderinformationen übereinstimmt.",
        "Street" : "Addresse",
        "Street2" : "Addresse2",
        "City" : "Stadt",
        "State" : "Bundesland",
        "Zip" : "Postleitzahl",
        "SaveBillingInfo" : "Rechnungsinformationen speichern",
        "SaveCard" : "Karteninfo speichern",
        "Expires" : "Gültig bis",
        "DeleteCardOnly" : "Kreditkarte entfernen",
        "DeletePaymentInfo" : "Entfernen Sie sowohl die Rechnungsinformationen als auch die Kreditkarte",
        "RemoveWholePaymentMethod" : "Alles löschen",
        "PayOnline" : "Zahlung Bestätigen",
        "PaymentStatusPaid" : "Bezahlt",
        "PaymentStatusPending" : "Ausstehend",
        "PaymentInfo" : "Zahlungsinformationen",
        "PaymentExplanation1" : "Die Kosten für ein SignHero Abonnement betragen 5€ pro Monat pro aktivem Nutzer. (Der angegebene Preis ist Nettopreis und enthält die Mehrwertsteuer nicht.) Zum angegebenen Preis wird die die jeweils gültige gesetzliche Mehrwertsteuer zusätzlich berechnet. Inaktive Benutzer werden nicht  berechnet. Wenn Sie während eines Abrechnungszeitraums Benutzer hinzufügen oder entfernen, berechnen wir automatisch, wie viel weniger oder mehr die nächste Rechnung betragen sollte, basierend darauf, wie viel des Abrechnungszeitraums zum Zeitpunkt der Änderung verbleibt. Wenn Sie sich anmelden, erhalten Sie von uns 14-tägige kostenlose Testperiode. Danach müssen Sie eine Kreditkarte für monatlich wiederkehrende Zahlungen registrieren, wenn Sie den Dienst weiterhin nutzen möchten. Ihre Kreditkarte wird jeden Monat automatisch belastet, je nachdem, wie viele Benutzer Sie für den Dienst registriert haben. Sie erhalten eine Quittung über Ihre monatliche Zahlung an die E-Mail-Adresse, die Sie bei der Registrierung Ihrer Kreditkarte angegeben haben.",
        "PaymentExplanation2" : "Sie müssen die Umsatzsteuer-Identifikationsnummer Ihres Unternehmens angeben, da SignHero die Umsatzsteuer von Kunden gemäß den finnischen und EU-Steuergesetzen einzieht. Für Unternehmen innerhalb der EU muss eine gültige Umsatzsteuer-ID angegeben werden. Wenn Ihr Land nicht in der Länder- oder Regionsauswahl aufgeführt ist, können Sie uns für alternative Zahlungsmethoden kontaktieren. Wir möchten Sie bitten uns mitzuteilen, wenn Ihr Land nicht auf der Liste ist und Sie sich aus diesem Grund entschieden haben, den Dienst nicht zu nutzen. Wir können es in der Zukunft hinzufügen und Sie dann darüber informieren!",
        "PaymentExplanation3" : "Wenn Sie keine Möglichkeit haben, eine Kreditkarte für die automatische Abrechnung hinzuzufügen, wenden Sie sich bitte an SignHero unter  <a class=='elem-pink' href='mailto:hello@signhero.io'>hello@signhero.io</a>.",
        "PaymentExplanation4" : "Haben Sie ein Problem mit Ihrer Rechnung? Noch weitere Fragen? Kontaktieren Sie uns unter <a class=='elem-pink' href='mailto:hello@signhero.io'>hello@signhero.io</a>!",
        "CountersPending" : "Die Statistik wird berechnet. Bitte versuchen Sie es nach einigen Minuten erneut.",
        "WholeOrganisation" : "Ganze Organisation",
        "OrganisationHas" : "Ihre Organisation hat derzeit",
        "ActiveUsers" : "aktive(n) Benutzer und",
        "InactiveUsers" : "inaktive(n) Benutzer.",
        "ChartDescriptionOrg" : "Die folgende Grafik zeigt die erstellten Unterzeichnungsprozesse und Dokumente der gesamten Organisation.",
        "ChartDescriptionUser" : "Das folgende Diagramm zeigt die erstellte Unterzeichnungsprozesse der ausgewählten Benutzer.",
        "SelectUsers" : "Benutzer auswählen",
        "From" : "Vom",
        "To" : "Bis zum",
        "AmountOfDocuments" : "Im ausgewählten Zeitraum ist die Anzahl der neuen Dokumente ",
        "AmountOfSignatureProcesses" : "und die Anzahl der neuen Unterzeichnungsprozessen ist",
        "Processes" : "Prozesse",
        "Documents" : "Dokumente",
        "Users" : "Benutzer",
        "UserActivity" : "Benutzeraktivität",
        "Signatures": "Unterschriften",
        "User" : "Benutzer",
        "Inactive" : "Inaktiv",
        "SignatureProcesses" : "Unterzeichnungsprozesse im Zeitraum",
        "AllSignatureProcesses" : "Alle Unterzeichnungsprozesse",
        "FreeTrialAlert1" : "Es sind noch",
        "FreeTrialAlert2" : "Tag(e) ihrer kostenlosen Testperiode übrig.",
        "ContactAdmin" : "Kein aktives Abonnement, bitte wenden Sie sich an Ihren Administrator!",
        "PaymentOverdueAlert" : "Zahlung überfällig, bitte fügen Sie eine gültige Karte hinzu",
        "PaymentOverdueAlertIgnore" : "Wenn Sie bereits eine gültige Karte hinzugefügt haben, ignorieren Sie bitte diese Nachricht",
        "PleaseAddValidCPayment" : "Kein aktives Abonnement, bitte fügen Sie eine gültige Zahlungsmethode hinzu!",
        "PleaseConfirmPayment" : "Kartenauthentifizierung erforderlich. Bitte prüfen Sie den \"Abrechnungsverlauf\"!",
        "PleaseConfirmPaymentMainPage" : "Keine gültige Zahlungsmethode oder Kartenauthentifizierung erforderlich. Bitte überprüfen Sie \"Mein Abonnement\"!",
        "ConfirmDeletePaymentMethod": "Möchten Sie die Rechnungs- und Zahlungkarten-informationen wirklich löschen?",
        "ConsentDialogTitle": "Abonnieren Sie SignHero mit wiederkehrenden Zahlungen?",
        "ConsentText1": "eSignieren mit SignHero ist schnell und reibungslos. Senden und signieren Sie PDFs, verfolgen Sie Prozesse, erinnern Sie Unterzeichner, greifen Sie auf Audit-Trails zu, arbeiten Sie in Teams und archivieren Sie signierte Dokumente.",
        "ConsentText2": "Die Kosten für ein SignHero Abonnement betragen 5€ pro Monat pro aktivem Nutzer + zzgl. Inaktive Benutzer werden nicht berechnet. Wenn Sie während eines Abrechnungszeitraums Benutzer hinzufügen oder entfernen, berechnen wir automatisch, wie viel weniger oder mehr die nächste Rechnung betragen sollte, basierend darauf, wie viel des Abrechnungszeitraums zum Zeitpunkt der Änderung verbleibt. Wenn Sie sich anmelden, erhalten Sie von uns 14-tägige kostenlose Testperiode. Danach müssen Sie eine Kreditkarte für monatlich wiederkehrende Zahlungen registrieren, wenn Sie den Dienst weiterhin nutzen möchten. Ihre Kreditkarte wird jeden Monat automatisch belastet, je nachdem, wie viele Benutzer Sie für den Dienst registriert haben. Nach der Registrierung Ihrer Kreditkarte wird Ihr Abonnement nach erfolgreicher Zahlung gültig und bleibt gültig, bis die Zahlung fehlgeschlagen ist. In Bezug auf Ihr SignHero-Abonnement erhalten Sie diese E-Mails:",
        "ConsentText3": "Sie müssen die Umsatzsteuer-Identifikationsnummer Ihres Unternehmens eingeben, da SignHero die Umsatzsteuer von Kunden gemäß den finnischen und EU-Steuergesetzen einzieht. Für Unternehmen innerhalb der EU muss eine gültige Umsatzsteuer-ID angegeben werden. Wenn Ihr Land nicht in der Länder- oder Regionsauswahl aufgeführt ist, können Sie uns für alternative Zahlungsmethoden unter hello@signhero.io kontaktieren. Wir möchten Sie bitten uns mitzuteilen, wenn Ihr Land nicht auf der Liste ist und Sie sich aus diesem Grund entschieden haben, den Dienst nicht zu nutzen. Wir können es in der Zukunft hinzufügen und Sie dann darüber informieren!",
        "ConsentText4": "Um das Abonnement zu kündigen, entfernen Sie Ihre Kreditkarteninformationen, so wird das aktuelle Abonnement am Ende des Abonnementzeitraums gekündigt. Sie können auch das Abonnement sofort kündigen, indem Sie auf die Schaltfläche „Abonnement kündigen“ klicken.",
        "ConsentEmailBullet1": "eine Mahnung vor dem Ablauf der Testperiode",
        "ConsentEmailBullet2": "eine Quittung über Ihre monatliche Zahlung an die von Ihnen angegebene E-Mail-Adresse",
        "ConsentEmailBullet3": "eine Benachrichtigung 7 Tage vor jeder Transaktion",
        "ConsentCheckBoxLabel1": "Ich habe die obigen Informationen gelesen",
        "ConsentCheckBoxLabel2": "Ich stimme zu, den SignHero-Dienst für wiederkehrende Zahlungen zu abonnieren",
        "Agree": "Ich stimme zu",
        "CurrentPlan" : "Aktuelles Abonnement",
        "SignHeroSubscription" : "SignHero-Abonnement",
        "CancelPlan" : "Abonnement kündigen",
        "CreatePlan" : "Abonnieren",
        "PriceInfo" : "5€ pro Monat pro aktivem Nutzer",
        "TaxTooltip" : "Bitte beachten Sie, dass der Preis keine Mehrwertsteuer enthält.",
        "PleaseAddValidCard" : "Bitte fügen Sie eine gültige Zahlungsmethode hinzu",
        "CancelPlanConfirm" : "Möchten Sie das Abonnement wirklich kündigen?\nSie verlieren die Restzeit der Testperiode oder die verbleibende Zeit auf Ihrem Abonnement, für die Sie bereits bezahlt haben -- und wir können Sie dafür nicht kompensieren.",
        "CreatePlanConfirm" : "Möchten Sie den SignHero-Dienst wirklich abonnieren?",
        "numbersDidNotMatch" : "Falscher Bestätigungscode. Bitte versuchen Sie es erneut.",
        "toProceedEnterCode" : "Geben Sie diesen Bestätigungscode ein, um fortzufahren: ",
        "subStatus!trialing" : "Testperiode endet",
        "subStatus!active" : "Aktiv",
        "subStatus!canceled" : "Storniert",
        "subStatus!incomplete" : "Unvollständig",
        "subStatus!incomplete_expired" :  "Abgelaufen",
        "subStatus!past_due" : "Überfällig",
        "ConfirmCreateSubNow" : "Sie können entweder jetzt oder später auf der Seite ‘Mein Abonnement’ ein neues Abonnement erstellen.",
        "CreateSubscriptionNow" : "Jetzt erstellen",
        "CreateSubscriptionLater" : "Später",
        "Country!Australia" : "Australien",
        "Country!Austria" : "Österreich",
        "Country!Belgium" : "Belgien",
        "Country!Brazil" : "Brasilien",
        "Country!Bulgaria" : "Bulgarien",
        "Country!Canada" : "Kanada",
        "Country!Chile" : "Chile",
        "Country!Croatia" : "Kroatien",
        "Country!Cyprus" : "Zypern",
        "Country!CzechRepublic" : "Tschechische Republik",
        "Country!Denmark" : "Dänemark",
        "Country!Estonia" : "Estland",
        "Country!Finland" : "Finnland",
        "Country!France" : "Frankreich",
        "Country!Germany" : "Deutschland",
        "Country!Greece" : "Griechenland",
        "Country!HongKong" : "Hongkong",
        "Country!Hungary" : "Ungarn",
        "Country!India" : "Indien",
        "Country!Indonesia" : "Indonesien",
        "Country!Ireland" : "Irland",
        "Country!Israel" : "Israel",
        "Country!Italy" : "Italien",
        "Country!Japan" : "Japan",
        "Country!Korea" : "Korea",
        "Country!Latvia" : "Lettland",
        "Country!Liechtenstein" : "Liechtenstein",
        "Country!Lithuania" : "Litauen",
        "Country!Luxembourg" : "Luxemburg",
        "Country!Malaysia" : "Malaysia",
        "Country!Malta" : "Malta",
        "Country!Mexico" : "Mexiko",
        "Country!Netherlands" : "Niederlande",
        "Country!NewZealand" : "Neuseeland",
        "Country!Norway" : "Norwegen",
        "Country!Poland" : "Polen",
        "Country!Portugal" : "Portugal",
        "Country!Romania" : "Rumänien",
        "Country!RussianFederation" : "Russische Föderation",
        "Country!SaudiArabia" : "Saudi-Arabien",
        "Country!Singapore" : "Singapur",
        "Country!Slovakia" : "Slowakei",
        "Country!Slovenia" : "Slowenien",
        "Country!SouthAfrica" : "Südafrika",
        "Country!Spain" : "Spanien",
        "Country!Sweden" : "Schweden",
        "Country!Switzerland" : "Schweiz",
        "Country!Taiwan" : "Taiwan",
        "Country!Thailand" : "Thailand",
        "Country!UnitedArabEmirates" : "Vereinigte Arabische Emirate",
        "Country!UnitedKingdom" : "Vereinigtes Königreich",
        "Country!UnitedStates" : "Vereinigte Staaten",
        "TaxType!au_abn" : "Australische Geschäfts-Steuernummer (AU ABN)",
        "TaxType!au_arn" : "Referenznummer des australischen Finanzamts",
        "TaxType!eu_vat" : "Europäische Umsatzsteuer-Identifikationsnummer",
        "TaxType!br_cnpj" : "Brasilianische CNPJ-Nummer",
        "TaxType!br_cpf" : "Brasilianische CPF-Nummer",
        "TaxType!ca_bn" : "Kanadische BN",
        "TaxType!ca_gst_hst" : "Kanadische GST/HST-Nummer",
        "TaxType!ca_pst_bc" : "Kanadische PST-Nummer (British Columbia)",
        "TaxType!ca_pst_mb" : "Kanadische PST-Nummer (Manitoba)",
        "TaxType!ca_pst_sk" : "Kanadische PST-Nummer (Saskatchewan)",
        "TaxType!ca_qst" : "Kanadische QST-Nummer (Québec)",
        "TaxType!cl_tin" : "Chilenische TIN",
        "TaxType!hk_br" : "Hongkong BR-Nummer",
        "TaxType!in_gst" : "Indische GST-Nummer",
        "TaxType!id_npwp" : "Indonesische NPWP-Nummer",
        "TaxType!il_vat" : "Israelische Mehrwertsteuer",
        "TaxType!jp_cn" : "Japanische Unternehmensnummer",
        "TaxType!jp_rn" : "Registrierungsnummer eines in Japan registrierten ausländischen Unternehmens",
        "TaxType!kr_brn" : "Koreanische BRN",
        "TaxType!li_uid" : "Liechtensteinische UID-Nummer",
        "TaxType!my_frp" : "Malaysische FRP-Nummer",
        "TaxType!my_itn" : "Malaysische ITN",
        "TaxType!my_sst" : "Malaysische SST-Nummer",
        "TaxType!mx_rfc" : "Mexikanische RFC-Nummer",
        "TaxType!nz_gst" : "Neuseeländische GST-Nummer",
        "TaxType!no_vat" : "Norwegische Umsatzsteuer-Identifikationsnummer",
        "TaxType!ru_inn" : "Russische INN",
        "TaxType!ru_kpp" : "Russische KPP",
        "TaxType!sa_vat" : "Saudi-Arabien Mehrwertsteuer",
        "TaxType!sg_gst" : "Singapurische GST",
        "TaxType!sg_uen" : "Singapurische UEN",
        "TaxType!za_vat" : "Südafrikanische Umsatzsteuer-Identifikationsnummer",
        "TaxType!es_cif" : "Spanische CIF-Nummer",
        "TaxType!ch_vat" : "Umsatzsteuer-Identifikationsnummer Schweiz",
        "TaxType!tw_vat" : "Taiwanesische Mehrwertsteuer",
        "TaxType!th_vat" : "Thailändische Mehrwertsteuer",
        "TaxType!ae_trn" : "Vereinigte Arabische Emirate TRN",
        "TaxType!gb_vat" : "Umsatzsteuer-Identifikationsnummer des Vereinigten Königreichs",
        "TaxType!us_ein" : "United States EIN"
    },
    "signflow": {
        "SendReminder": "Aktivierungserinnerung senden",
        "SessionType!password": "Passwort",
        "SessionType!auth_code": "Anwendung",
        "Start": "Neu anfangen",
        "Error!Unexpected": "Ein unerwarteter Fehler ist aufgetreten. Versuchen Sie es später nochmal.",
        "ButtonBack": "Zurück",
        "ButtonGoBack": "Zurück",
        "ButtonNext": "Weiter",
        "Create": "Erstellen",
        "Complete": "Archiv",
        "SearchSignFlows": "Suche nach Unterzeichner, Titel oder Dokument",
        "AllFlows": "Alle",
        "PendingMeFlows": "Auf meine Unterschrift wartend",
        "PendingOthersFlows": "Auf andere wartend",
        "CanceledFlows": "Abgebrochene",
        "CompletedFlows": "Abgeschlossene",
        "ShowGroupProcesses": "Alle Unterzeichnungsprozesse der Organisation",
        "CancellationReasons": "Grund: ${cancellationReasons}",
        "SignedDocuments": "Document(e):",
        "CreatedByTitle": "Erstellt von:",
        "CanceledByTitle": "Abgebrochen von:",
        "PendingSignaturesTitle": "Wartend:",
        "PendingMeAlert": "<span class='elem-pink'>Sie</span> haben nicht unterzeichnet.",
        "PendingOthersAlert": "Auf <span class='elem-pink'>${count}</span> Person(en) wartend",
        "SignerStatusTitle": "Status:",
        "ReminderHistory": "Erinnerungsverlauf",
        "SignerStatus1": "Die Einladung wurde erstmals geöffnet: ",
        "SignerStatus2": "Die Einladung wurde nie geöffnet.",
        "SignerStatus3": "Signiert am ",
        "SignerStatus4": "Beim Ersteller ausstehend.",
        "SignerStatus5": "Ausstehend.",
        "SendReminders": "Erinnern Per E-Mail",
        "NoProcessesFound": "Wir haben keine Unterzeichnungsprozesse gefunden, die Ihren Filtern entsprechen",
        "LastEmailReminder": "Letzte Erinnerung am: <b>${timestamp}</b>",
        "NoRemindersYet": "noch nicht gesendet",
        "EmailReminderSuccess": "E-Mail-Erinnerungen gesendet.",
        "ReminderSentBy": "gesendet von",
        "CancelProcessDialogTitle": "Unterzeichnungsprozess abbrechen",
        "CancellationReasonsPlaceHolder": "Grund des Abbrechens",
        "CancelProcess": "Prozess abbrechen",
        "DeleteProcess": "Prozess entfernen",
        "RestartProcess": "Neu anfangen",
        "ConfirmDeleteProcess": "Möchten Sie sicher den Prozess entfernen?",
        "ResendDownloadInvitation": "Download-Link erneut senden",
        "DownloadInvitationEmailSuccess": "E-Mail gesendet.",
        "DownloadInvitationEmailFail": "Senden fehlgeschlagen",
        "Me": "Ich",
        "SignFlowDocumentsHeading": "Was wird unterzeichnet?",
        "FlowSettings": "Extras",
        "FlowTitlePlaceholder": "Benennen Sie den Prozess für eine einfache Archivierung",
        "Signers": "Unterzeichner *",
        "AddSigner": "Weitere Unterzeichner hinzufügen",
        "AddSignerGroup": "Unterzeichner aus Datei hinzufügen",
        "AddressBookChooseFile": "Datei wählen",
        "AddressBookInfoHeader": "So fügen Sie Unterzeichner aus einer Datei hinzu",
        "AddressBookInfo": "Es sollte eine Datei mit der Erweiterung .txt/.csv sein, wobei jeder Unterzeichner in einer eigenen Zeile steht und ein Komma zwischen den Feldern steht.",
        "AddressBookInfoExampleHeader": "Zum Beispiel die Datei namens BoardMembers.txt mit folgendem Inhalt:",
        "AddressBookInfoExampleContent": "James Smith, james.smith@example.com<br>Mary Jones, mary.jones@example.com<br>John Williams, john.williams@example.com<br>Linda Taylor, linda.taylor@example.com",
        "NotAnAddressBook": "Die Datei enthielt keine Empfängernamen und -adressen.",
        "CheckFileEncoding": "Die Datei enthält nicht unterstützte Zeichen. Bitte stellen Sie sicher, dass die Dateikodierung UTF-8 ist.",
        "SignersName": "Vor- und Nachname",
        "SignersEmail": "E-Mail",
        "EmailSubject": "E-Mail-Titel:",
        "EmailMessage": "Nachricht:",
        "EditInvitation": "Unterzeichnugseinladung bearbeiten",
        "ChooseInvitation": "Einladungsvorlage auswählen",
        "UseDragAndDropSignature": "Unterschriftspositionen festlegen",
        "StartNow": "Unterzeichnen",
        "StartSigningNow": "Sie können entweder jetzt oder später unter der Registerkarte Laufend unterzeichnen.",
        "RequestEmailSendSigners": "Die E-Mail mit der Unterschriftsanfrage wurde and die Unterzeichner abgesendet. ",
        "InformProgressNotifications": "Sie werden per E-Mail über den Fortschritt des Unterzeichnungsprozesses informiert.",
        "Later": "Später",
        "Ok" : "Ok",
        "DefaultEmailSubject": "${$cc_user_info.name} lädt Sie zum eSigning ein",
        "DefaultEmailMessage": "${$cc_user_info.name} von ${$cc_user_info.organization.display_name} (${$cc_user_info.email}) hat Sie zum eSignieren mit SignHero eingeladen.",
        "Signer": "Unterzeichner",
        "Created": "Erstellt",
        "Canceled": "Abgebrochen",
        "Sign": "Unterzeichnen",
        "Send": "Senden",
        "GoToSign": "Zum Unterzeichnen",
        "Download": "Herunterladen",
        "SignedBy": "Unterzeichnet von:",
        "WhoIsSigning": "Wer unterzeichnet?",
        "OnlyMe": "Nur ich",
        "OnlyOthers": "Nur andere",
        "MeAndOthers": "Ich und andere",
        "Email": "E-Mail",
        "Add": "Hinzufügen",
        "numbersDidNotMatch": "Falscher Bestätigungscode. Bitte versuchen Sie es erneut.",
        "toProceedEnterCode": "Geben Sie diesen Bestätigungscode ein, um fortzufahren: ",
        "Confirm": "Bestätigen",
        "Continue": "Weiter",
        "Cancel": "Abbrechen",
        "Reset": "Zurücksetzen",
        "selectFromDisk": "Lokal",
        "UploadFile": "Hochladen",
        "fileTooLarge": "Die maximale Dateigröße ist ${maxSize}.",
        "allFilesTooLarge": "Die maximale Gesamtgröße für alle Dateien beträgt ${maxSize}. Sie versuchen jetzt insgesamt ${uploadedSize} hochzuladen.",
        "fileNumberTooLarge": "Laden Sie höchstens ${maxFileNumber} Dateien hoch.",
        "uploadError": "Das Format der Datei ist nicht korrekt oder die Datei ist bereits  unterzeichnet.",
        "select": "Auswählen",
        "OngoingProcess" : "Laufende Prozesse",
        "GoogleDrive": "Google Drive",
        "DownloadFailed": "Die Datei konnte nicht heruntergeladen werden.",
        "Error!FilesOutdated" : "Sitzungszeitüberschreitung. Bitte laden Sie Dateien nochmal hoch und füllen Sie gegebenenfalls das Formular noch einmal aus.",
        "Error!DocumentError" : "Dis Sitzung ist abgelaufen oder das Format der Datei ist nicht korrekt. Bitte laden Sie Dateien nochmal hoch und füllen Sie gegebenenfalls das Formular noch einmal aus.",
        "OnlyPdf" : "Akzeptierte Dateitypen sind pdf",
        "OnlyCancelledProcessAllowed": "Sie können nur abgebrochene Prozesse neu starten.",
        "CreateNewTags" : "Neues Tag erstellen",
        "CreateNewTagsHint" : "Drücken Sie die Eingabe-Taste, um ein neues Tag hinzuzufügen (maximal 20 Zeichen)",
        "ManageTags" : "Tags verwalten",
        "Tags" : "Tags",
        "FilterByTag" : "Nach Tag filtern",
        "ErrorTagExists" : "Tag existiert bereits!   ",
        "ContactAdmin" : "Kein aktives Abonnement, bitte wenden Sie sich an Ihren Administrator!",
        "PaymentOverdueAlert" : "Zahlung überfällig, bitte fügen Sie eine gültige Zahlungskarte hinzu",
        "PaymentOverdueAlertIgnore" : "Ignorieren Sie diese Meldung bitte, wenn Sie bereits eine gültige Zahlungskarte hinzuzugefügt haben.",
        "PleaseAddValidCPayment" : "Kein aktives Abonnement, bitte fügen Sie eine gültige Zahlungsmethode hinzu!",
        "PleaseConfirmPayment" : "Authentifizierung erforderlich. Bitte überprüfen Sie die aktuelle Rechnung auf der Registerkarte \"Rechnungsverlauf\"!",
        "PleaseConfirmPaymentMainPage" : "Keine gültige Zahlungsmethode oder Authentifizierung erforderlich. Bitte überprüfen Sie es auf der \"Mein Abonnement\" Seite!",
        "ConfirmDeletePaymentMethod": "Möchten Sie die Rechnungs- und Zahlungkarten-informationen wirklich löschen?",
        "OwnedBy" : "Gehört zu:",
        "SavedTemplates" : "Einladungsvorlage auswählen",
        "CreateTemplateTitle" : "Einladungsvorlage speichern",
        "NameTemplate" : "Benennen Sie die Einladungsvorlage",
        "NameTemplateHint" : "Wird nur für die Suche verwendet, es wird nicht in Einladungen angezeigt.",
        "TemplateCreated" : "Einladungsvorlage gespeichert.",
        "SignatureProcesses" : "Unterzeichnungsprozesse im Zeitraum",
        "AllSignatureProcesses" : "Alle Unterzeichnungsprozesse",
        "DeleteTemplateTitle" : "Geteilte Einladungsvorlage löschen",
        "DeleteTemplateConfirm" : "Möchten Sie die Einladungsvorlage wirklich löschen? Dies wirkt sich auf die gesamte Organisation aus.",
        "Update" : "Aktualisieren",
        "Edit" : "Bearbeiten",
        "Save" : "Speichern",
        "ExplainTemplate1" : "Die Organisation hat keine gespeicherten Einladungsvorlagen. Einladungsvorlagen können nach der Bearbeitung gespeichert werden.",
        "ExplainTemplate2" : "Speichern kann man – muss man aber nicht! ",
        "ExplainTemplate3" : "Die aktuelle Signatureinladung kann ohne Speichern verwendet werden.",
        "ExplainTemplate4" : "Sie können häufig verwendete Einladungsvorlagen speichern. Die erstellten Vorlagen sind für die gesamte Organisation sichtbar und können von jedem in der Organisation bearbeitet oder gelöscht werden.\nDie aktuelle Signatureinladung kann ohne Speichern verwendet werden.",
        "UpdateTemplate" : "Die Vorlage ist für jeden in der Organisation sichtbar, bearbeitbar und löschbar.\n\nVorlagenname: ",
        "DeleteTemplate" : "Vorlage gelöscht.",
        "Error!TemplateNameExist": "Die Vorlage existiert bereits, möchten Sie sie überschrieben?",
        "Error!TemplateNameEmpty": "Der Vorlagenname fehlt!",
        "TemplateNoname" : "noname",
        "ProcessesTabName1" : "Meine Unterzeichnungsprozesse",
        "ProcessesTabName2" : "Meine Unterzeichnungseinladungen",
        "ShowOnly12MonthInfo" : "Unterzeichnungseinladungen der letzten 12 Monate",
        "MaxSigners" : "Die maximale Anzahl an Unterzeichnern für einen Prozess beträgt ",
        "MaxSigners1" : "Bitte entfernen Sie ",
        "MaxSigners2" : " Unterzeichner.",
        "MaxSigners3" : "Weitere Unterzeichner können nicht hinzugefügt werden."
    },
    "message": {
        "ReportProblem" : "Problem melden",
        "IForgotMyPassword" : "Passwort zurücksetzen",
        "NavigationMenuButton": "Menü",
        "NavigationAccountButton": "Konto",
        "NavigationContactButton": "Kontakt",
        "NavItemIntroduction": "Einleitung",
        "NavItemChooseEasy": "Wählen Sie einfach",
        "NavItemAccount": "Konto",
        "NavItemAPIsIntegrations": "APIs & Integrationen",
        "NavItemDoubtsWorries": "Zweifel & Fragen",
        "NavItemThisIsUs": "Das sind wir",
        "NavItemContactUs": "Kontakt",
        "MainSlogan": "SignHero - Einfacher geht es nicht!",
        "SubSloganPart1": "Es ist eine Revolte gegen Komplexität, überdimensionierte Prozesse ",
        "SubSloganPart2": "und den alten Weg, Dinge zu erledigen!",
        "GoToRegister": "Kostenlos testen",
        "TitleLazy": "Wählen Sie einfach",
        "TextLazyLine1": "Bankverbindungen, Vorlagen und Skizzen sind überflüssig;",
        "TextLazyLine2": "sie repräsentieren überdimensionierte Prozesse, die Zeit und Geld verschlingen.",
        "TextLazyLine3": "SignHero Fans wollen sich auf das konzentrieren, was zählt, also geben wir Ihnen nur das, was Sie wirklich brauchen.",
        "MetricsTitle": "Durchschnittliche wöchentliche Nutzungszahlen:",
        "Metrics1" : "6000+ SignHero-Nutzer",
        "Metrics2" : "2000+ Verträge unterzeichnet",
        "Metrics3" : "100+ neue Team Konten",
        "TitleAccount": "Konto",
        "TextAccount": "Das SignHero-Konto beinhaltet alles, was Sie wirklich für die elektonische Signatur benötigen:",
        "Feature1": "Senden und Unterzeichnen von PDFs",
        "Feature2": "Verlaufsverfolgung",
        "Feature3": "Erinnern Sie Unterzeichner",
        "Feature4": "Arbeiten in Teams",
        "Feature5": "Zugriff auf den Audit Trail",
        "Feature6": "Archivieren von Dokumenten",
        "UnlimitedSigners": "Unbegrenzte Anzahl von Unterzeichnern und Einladungen",
        "EasyRegister": "Registrieren Sie sich mit Ihrem Namen, Ihrer E-Mail-Adresse und Ihrem Firmennamen",
        "Register": "Erstellen Sie Ihr Konto",
        "QuoteMarko": "\"We sign nearly all contracts with SignHero. Feedback has been really positive, and people keep asking how they can start using it too.\"",
        "AuthorMarko": "Marko Vihiniemi",
        "CompanyMarko": "CEO, Salon Vilpas Koripallo Oy",
        "QuoteHenri": "\"I was fascinated by the increased efficiency compared with old signing methods. Our whole team started using SignHero, and it has received lots of praise!\"",
        "AuthorHenri": "Henri Kämäräinen",
        "CompanyHenri": "Head of Partnerships, FallUp, Aalto Entreprenership Society",
        "QuoteJuho": "\"Usually I don’t believe things that seem too good to be true, but in this case, you get exactly what’s promised.\"",
        "AuthorJuho": "Juho Nenonen",
        "CompanyJuho": "Co-founder & CEO, Sportspot",
        "QuoteKarolina": "\"First, we tried this out of curiosity, but now, our legal advisers couldn’t imagine not using SignHero! We primarily eSign contracts for legal services and formal legal opinions.\"",
        "AuthorKarolina": "Karolina Bonarska-Lenarczyk",
        "CompanyKarolina": "Legal adviser",
        "QuoteMatias": "\"Less time spent on unproductive work, less paper, and a more secure way to sign. SignHero is a great solution!\"",
        "AuthorMatias": "Matias Vihko",
        "CompanyMatias": "Chairman of the Board, Koju Film Company",
        "QuoteKalle": "\"SignHero is very straightforward and eases property management work, which involves a lot of signing.\"",
        "AuthorKalle": "Kalle Grönqvist",
        "CompanyKalle": "CEO, Fluxio",
        "QuoteMonika": "\"We wanted a modern solution that makes life easier for our customers. SignHero improves the booking process and registration – leaving us more time for our furry guests.\"",
        "AuthorMonika": "Monika Jackowska-Ziemba",
        "CompanyMonika": "Co-owner, DOGStation",
        "QuoteDominika": "\"HR can finally sign paperlessly! SignHero supports our signing process and also conveniently archives all signed documents.\"",
        "AuthorDominika": "Dominika Pusz",
        "CompanyDominika": "President of the Board, MDJ Recruitment",
        "QuoteVisa": "\"I love SignHero!\"",
        "AuthorVisa": "Visa Tuovinen",
        "CompanyVisa": "Roast Master, Lehmus Roastery",
        "TitlePricing": "Preis",
        "MonthlyPrice": "5€ / Benutzer / Monat",
        "MonthlyBill": "(Monatliche Abrechnung. Der angegebene Preis ist Nettopreis und enthält die Mehrwertsteuer nicht. Zum angegebenen Preis wird die jeweils gültige gesetzliche Mehrwertsteuer zusätzlich berechnet.)",
        "Benefits1": "Unbegrenzte elektronische Unterzeichnungen",
        "Benefits2": "Archiv für Ihre Dokumente",
        "Benefits3": "Teamkonto für mehrere Benutzer",
        "Benefits4": "Unterzeichner benötigen kein Konto",
        "ButtonFreeTrial": "14-tägige Testperiode starten",
        "CreditCardInfo": "Keine Kreditkarte nötig",
        "TitleAPI": "APIs & Integrationen",
        "IntegrateSignHero": "Integrieren Sie unsere Unterzeichnungs-Schaltfläche, wo immer Sie sie brauchen.",
        "ToIntroBTN": "Einleitung",
        "ToDocsBTN": "Handbuch",
        "ToGmailBTN": "Gmail",
        "ToZapierBTN": "Zapier",
        "QuoteOlli": "\"Integrating electronic contract generation and signing into a WordPress environment saves our clients tens of hours of time.\"",
        "AuthorOlli": "Olli Nyqvist",
        "CompanyOlli": "CEO, WebAula",
        "TitleDoubts": "Zweifel & Fragen",
        "DoubtsLegal": "Ist eSigning überhaupt legal?",
        "TitleShortAnswer": "Kurze Antwort:",
        "AnswerLegalParagraph1": "Selbstverständlich. Es gibt sie schon seit einem Jahrzehnt.",
        "AnswerLegalParagraph2": "Ein Papier mit einem Stift zu unterschreiben ist keine besonders sichere Art des Unterzeichnens. Das Gesetz ist auf unserer Seite - Schauen Sie sich die",
        "eIDAS": "eIDAS",
        "ESIGN": "ESIGN",
        "And": "und",
        "Acts": "Rechtsakte an.",
        "TitleLongAnswer": "Ausführlichere Antwort:",
        "LegalLongAnswerParagraph1": "Wie Sie wissen, ist nur das Unterschreiben eines Papieres keine besonders sichere Art, einen Vertrag abzuschließen.\nHandschriftliche Unterschriften können leicht gefälscht, angezweifelt und verloren gehen. Tatsache ist, während Sie immer noch mit Stift und Papier beschäftigt sind, haben Sie Ihre Sicherheit noch nicht vergrößert.",
        "LegalLongAnswerParagraph2": "Außerdem gilt in der EU ein Vertragsfreiheitsgesetz, nach dem Sie wählen können, wie Ihre Verträge bestätigt werden sollen. Prüfen Sie, dass Ihr Unternehmen keinen besonderen Einschränkungen unterliegt (z. B. Geldwäschegesetz) und dann kann es losgehen mit dem eSignieren.",
        "LegalLongAnswerParagraph3": "Authentifizierung ist wie eine Versicherung. Haben Sie zum Beispiel Ihren Ausweis gezeigt, als Sie Ihren aktuellen Arbeitsvertrag unterschrieben haben? Einige Vereinbarungen sind risikoarm und erfordern daher nur eine leichte Versicherung; In diesem Fall reicht die E-Mail-Authentifizierung aus. ",
        "LegalLongAnswerParagraph4": "<span class='doubts-break1'>SignHero wird derzeit verwendet für: Handelsverträge / Arbeitsverträge / Geheimhaltungsvereinbarungen</span> <span class='doubts-break2'>/ Besprechungsprotokolle / Mietverträge</span>",
        "LegalLongAnswerParagraph5": "<span class='doubts-break1'>Auf SignHero-Standards basierende Signaturen sind mit  den Verordnungen UETA, Esign Act und eIDAS bezüglich</span> <span class='doubts-break2'>elektronischer Unterschriften konform.</span>",
        "DoubtsPrice": "Wie funktioniert das? Nur 5€?",
        "PriceShortAnswerParagraph1": "<span class='doubts-break1'>Sie können mit nur ein Paar Klicks Dokumente unterzeichnen und archivieren lassen. Einladungen zum Unterzeichnen werden per E-Mail gesendet, und zum Unterzeichnen ist kein Konto erforderlich. Der Preis beträgt 5€ pro Benutzer und Monat. Und es wird automatisch monatlich abgerechnet, sobald Sie Ihre Kreditkarteninformationen eingegeben haben. Sie können jederzeit kündigen, Sie zahlen also</span> <span class='doubts-break2'>jeweils für einen Monat.</span>",
        "PriceLongAnswerParagraph1": "SignHero ermöglicht es Ihnen, PDF-Dokumente mit Computern und mobilen Geräten zu signieren, und es kann mit unserer öffentlichen API in jedes CRM, digitale Archiv oder andere Dokumentenverwaltungssystem integriert werden. Sie können durch unseres SignHero-Gmail-Plug-in sogar aus Ihrem Gmail-Konto PDF-Dokumente unterschreiben, ohne Ihren Posteingang verlassen zu müssen.",
        "PriceLongAnswerParagraph2": "Sie können unverbindlich ein Konto erstellen für eine 14-tägige kostenlose Testperiode. Zur Registrierung sind keine Kreditkarteninformationen erforderlich. In der kostenlosen Testperiode sind alle Funktionen erreichbar, sodass Sie Signhero gründlich testen können. Und keine Sorge, wir spammen nicht, und versenden keine Newsletter.",
        "PriceLongAnswerParagraph3": "Der Preis beträgt 5€ pro Benutzer und Monat. Als zahlender Benutzer können Sie Unterschriftsanfragen senden, Unterzeichnungsereignisse überwachen, Ihre Dokumente im Archiv speichern und als Team an einem Organisationskonto arbeiten. Sie können Ihrem Konto nahezu unbegrenzt viele Teammitglieder hinzufügen und jeder neue Benutzer fügt weitere 5 € zu Ihrem monatlichen Abonnementplan hinzu.",
        "PriceReasons1": "At some point SignHero needs to be able to stand on its own, and not to be forever supported by a loving parent company (Avaintec).",
        "PriceReasons2": "We are not able to grow and create better experience for the lazy users unless we get some money to pay our bills.",
        "PriceReasons3": "Every week somebody approaches us, and asks how we are exploiting their data and scamming them with a brilliantly disguised free service. Well, not anymore. We are absolutely certain that after you start paying 5€ per month, you’ll trust us more. Who wouldn’t trust a capitalist?",
        "DoubtsSaveTime": "What can I do with all the time I save?",
        "SaveTimeAnswer": "Have some coffee, go fishing, actually don’t, get some work done, call your mum, do your\ntaxes, log in your hours, recycle, save the world, play with your dog, play with your friend’s\ndog, learn an instrument, write a poem, bake a cake, eat a cake, get a new haircut, do\ncartwheels across the lawn, travel the world, read a book, design your own destiny, create a\nreligion, bust a myth, self-employ, go to a startup event, raise money, fail fast, take a nap,\nbe lazy, repeat.",
        "TitleThisIsUs": "Das sind wir",
        "LinkedIn": "SignHero LinkedIn",
        "CompanyName": "Avaintec",
        "CompanyAddress": "Kansakoulukuja 3, 00100 Helsinki",
        "CompanyWeb": "www.avaintec.com",
        "TitleMoreQuestion": "Weitere Fragen?",
        "SignHeroEmail": "hello@signhero.io",
        "ApiDocsLink": "API docs",
        "TermOfUseLink": "Nutzungsbedingungen",
        "PrivacyPolicyLink": "Datenschutzbestimmungen",
        "CopyrightYear": "© " + new Date().getFullYear() + " ",
        "CompanyOyName": "Avaintec Oy",
        "RegulationNotice": "Elektronische Unterschriften haben in den USA und der EU denselben rechtlichen Status wie handschriftliche Unterschriften dank der ",
        "ESignAct": "ESIGN Act",
        "eIDASRegulations": "eIDAS-Verordnung",
        "Docs": "Documentation",
        "SignIn": "Einloggen",
        "TermsOfUse": "Nutzungsbedingungen",
        "ThisLocale": "DE",
        "WelcomeToSignHero": "Willkommen bei SignHero. Bitte geben Sie Ihre E-Mail ein und klicken Sie auf \"Weiter.\". Sie werden  eine E-Mail erhalten, mit der Sie zum ersten Mal Ihr Passwort festlegen und sich anmelden können.",
        "ConfirmAccountInfo": "Bitte überprüfen Sie Ihre E-Mail und befolgen Sie die darin enthaltenen Anweisungen, um Ihr Passwort festzulegen und sich anzumelden.",
        "MustBeEmail": "Geben Sie eine E-mail Adresse ein.",
        "MustBeValidFullName": "Geben Sie einen gültigen Namen ein.",
        "HyperlinksNotAllowed": "Hyperlinks sind nicht erlaubt.",
        "MustBeUniqueEmail": "Diese E-Mail wurde bereits hinzugefügt.",
        "EnterValidCharacters": "Bitte geben Sie nur unterstützte Zeichen ein.",
        "UserSignedUp": "Willkommen bei SignHero! Wir haben Ihnen eine E-Mail mit Anweisungen zur Aktivierung Ihres Kontos gesendet. Wenn Sie bereits ein Konto haben, können Sie Ihr Passwort auf der Anmeldeseite zurücksetzen.",
        "locale!fi": "Suomi - FI",
        "locale!en": "English - EN",
        "locale!pl": "Polski - PL",
        "locale!de" : "Deutsch - DE",
        "locale!sv" : "Svenska - SV",
        "locale!es" : "Español - ES",
        "locale!fr" : "Français - FR",
        "locale!nl" : "Nederlands - NL",
        "locale!th" : "ภาษาไทย - TH",
        "SessionType!password": "Passwort",
        "SessionType!auth_code": "Anwendung",
        "YouHaveBeenSignedOut": "Sie sind abgemeldet.",
        "Error!UserExists": "Es existiert bereits ein Benutzer mit der von Ihnen angegebenen E-Mail-Adresse oder Benutzernamen. Wenn Sie Ihr Passwort vergessen haben, klicken Sie auf \"Anmelden\" und ändern Sie ihr Passwort über den Link \"Passwort zurücksetzen\".",
        "Save": "Speichern",
        "Error!Unexpected": "Ein unerwarteter Fehler ist aufgetreten. Versuchen Sie es später noch einmal.",
        "Create": "Erstellen",
        "Complete": "Archivieren",
        "Sign": "Unterzeichnen",
        "Welcome": "Willkommen",
        "CompleteProcessInfo": "Sie können das/die Dokument(e) nicht unterzeichnen, da diese Unterschriftsanfrage geschlossen wurde. Möglicherweise haben Sie dieses/diese Dokument(e) bereits unterschrieben oder die Unterschriftsanfrage wurde vom Absender storniert.",
        "SignInHere": "Anmelden",
        "ReasonTitle": "WAHRSCHEINLICHE GRÜNDE",
        "Reason1": "Sie haben dieses Dokument bereits unterzeichnet",
        "Reason2": "Einer der Unterzeichner hat die Unterschrift verweigert",
        "Reason3": "Die Einladung wurde vom Absender storniert",
        "Reason4": "Die Einladung ist nach einem Jahr abgelaufen",
        "Reason5": "Sie haben  die Adresse falsch eingegeben",
        "Reason6": "Sie haben eine Erinnerung erhalten und der alte Link ist automatisch abgelaufen",
        "SeeYouSoon" : "Bis bald",
        "HowToOpenExpiredSession" : "Um die Sitzung erneut zu öffnen, klicken Sie bitte auf den Link in Ihrer E-Mail.",
        "ClearCookieWarning": "Wenn Sie ein öffentliches oder gemeinsam genutztes Gerät verwenden, denken Sie bitte daran, Ihre Cookies, Ihren Verlauf und Ihren Cache zu löschen.",
        "JustASec": "Eine Sekunde",
        "SorryUnexpectedError": "Leider ist diese Seite nicht verfügbar.",
        "InvalidLoginUrl": "Der Link, dem Sie gefolgt sind, ist möglicherweise defekt oder die Einladung wurde entfernt.",
        "InvitationInvalidatedReason": "Der Link, dem Sie gefolgt sind, ist möglicherweise defekt oder die Einladung wurde entfernt.",
        "You": "Sie",
        "DownloadPageTitle": "Unterzeichnete Dokumente anzeigen und herunterladen",
        "DownloadLinkInfo" : "Dieser Link bleibt ein Jahr nach Abschluss des Unterzeichnungsprozesses aktiv.",
        "Download": "Herunterladen",
        "OpenAuditTrail": "Audit-Trail anzeigen",
        "ProcessNotFound": "Der Unterzeichnungsprozess wurde nicht gefunden. Bitte überprüfen Sie die Unterzeichnungsprozess-ID.",
        "SignatureEvents": "Unterschriften",
        "ProcessStatus!COMPLETED": "Abgeschlossen",
        "ProcessStatus!CANCELED": "Abgelehnt",
        "ProcessStatus!PENDING": "Anstehend",
        "ProcessStatus!UNKNOWN": "Unbekannt",
        "SignatureProcessId": "ID",
        "SignatureProcess": "Unterzeichnungsprozess",
        "SignatureFlowCreated": "Erstellt",
        "SignatureFlowStatus": "Status",
        "NumberOfDocuments": "Dokumente",
        "SignedBy": "Unterzeichnet von",
        "SignTime": "Signaturzeit",
        "AuthType": "Authentifizierungsart",
        "AuthMethod!verification_code": "E-Mail-Verifizierung",
        "AuthMethod!access_token": "SignHero-Konto",
        "AuthMethod!strong_auth": "Starke",
        "AuthMethod!otp_token": "Drittpartei",
        "AuthMethod!otac": "E-mail",
        "AuthMethod!signer_token": "E-mail",
        "AuthMethod!on_site": "In-person",
        "PrivacyPolicy": "Datenschutzbestimmungen",
        "EnterYourDetailsBelow": "Bitte geben Sie die notwendigen Angaben ein.",
        "ThirdParty": "Drittpartei",
        "SignUpButtonLabel": "Weiter",
        "NameAndSurname": "Ihr Vor- und Nachname",
        "Email": "E-mail",
        "Company": "Organisation",
        "ConfirmAccount": "Weiter",
        "Confirm": "Bestätigen",
        "Cancel": "Abbrechen",
        "ConfirmAccountTitle": "Legen Sie ein Passwort für Ihr <span class='elem-pink'>SignHero</span> Konto fest",
        "ResetPasswordTitle": "Passwort für Ihr <span class='elem-pink'>SignHero</span> Konto zurücksetzen",
        "ResetPassword": "Passwort bestätigen",
        "Reset": "Zurücksetzen",
        "Organization": "Organisation",
        "WelcomeMessage": "Melden Sie sich bei <span class='elem-pink'>SignHero</span> an",
        "WelcomeText": "Bitte geben Sie die notwendigen Angaben ein.",
        "DontHaveSignHeroAccount": "Haben Sie kein <span class='elem-pink'>SignHero</span> Konto?",
        "GetStarted": "Konto Erstellen",
        "AlreadyHaveSignHeroAccount": "Haben Sie bereits ein <span class='elem-pink'>SignHero</span> Konto?",
        "CreateYourSignHeroAccount": "Erstellen Sie Ihr <span class='elem-pink'>SignHero</span> Konto",
        "AcceptTermsOfUseAndPrivacyPolicy": "Indem Sie auf Weiter klicken, akzeptieren Sie die <a class='terms-disclaimer-link' href='terms-of-use.html' target='_blank'>Nutzungsbedingungen</a> und <a class='terms-disclaimer-link' href='privacy-policy.html' target='_blank'>Datenschutzbestimmungen</a> von SignHero.",
        "YouFoundApi": "You found SignHero API!",
        "CheckApiDocumentation": "To get started, check out our latest <a class=='elem-pink' href='${apiDocsLink}'>API documentation</a>.",
        "AuditTrail" : "Audit trail",
        "EnterProcessId" : "Geben Sie die Signaturprozess-ID ein, die in jedem Dokument im Signaturfeld angezeigt wird:",
        "SubmitID" : "Eingeben",
        "NoProcessId" : "Bitte geben Sie die Prozess-ID ein.",
        "ConfirmError1" : "Bitte fordern Sie auf der Seite ",
        "ConfirmError2" : " eine neue Einladung oder einen Link zum Zurücksetzen des Passworts an.",
        "SignHeroDotIo" : "SignHero.io",
        "ResetPWError" : "Dieser Link ist möglicherweise abgelaufen.<br>Gehen Sie auf die <a href='/#/signin' class='elem-pink'>Anmeldeseite</a>, um Ihr Passwort zurückzusetzen.",
        "Error!tax_id_invalid" : "Ungültige USt - IdNr.",
        "CreditCardSaved" : "Kreditkarte wurde hinzugefügt.",
        "BillingInformationSaved" : "Rechnungsinformationen gespeichert.",
        "dateFormat" : "MMM dd, yyyy",
        "timeFormat" : "dd.MM, HH:mm",
        "Error!card_declined" : "Die Kreditkarte wurde vom Zahlungsgateway abgelehnt.",
        "Error!incorrect_cvc" : "Die Kreditkarte wurde vom Zahlungsgateway abgelehnt.",
        "Error!expired_card" : "Die Kreditkarte wurde vom Zahlungsgateway abgelehnt.",
        "Error!processing_error" : "Die Kreditkarte wurde vom Zahlungsgateway abgelehnt.",
        "Contact" :  "Kontakt",
        "name" : "Name",
        "email" : "E-mail",
        "LoginEmail" : "E-mail",
        "EnterYourEmail" : "Bitte geben Sie Ihre E-Mail-Adresse ein:",
        "Password" : "Passwort",
        "HelloThere" : "Hallo,",
        "ChangePassword" : "Passwort ändern",
        "CurrentPassword" : "Aktuelle Passwort",
        "NewPassword" : "Neues Passwort",
        "RepeatPassword" : "Passwort wiederholen",
        "PasswordSet" : "Passwort festgelegt.",
        "SignHeroSentPasswordResetRequest" : "Anfrage zum Zurücksetzen des Passworts gesendet, wenn Sie ein registrierter Benutzer sind. Achten Sie auf Ihre E-Mails!",
        "SignHeroSentConfirmTokenRequest" : "Neue Kontobestätigung angefordert. Achten Sie auf Ihre E-Mails!",
        "PP!MinLength" : "Mindestens 10 Zeichen",
        "PP!MaxLength" : "Maximal 128 Zeichen",
        "PP!RepeatingChars" : "Keine dreifachen Zeichen",
        "PP!Lowercase" : "Einige Kleinbuchstaben",
        "PP!Uppercase" : "Einige Großbuchstaben",
        "PP!Numbers" : "Einige Zahlen",
        "PP!Specials" : "Einige Sonderzeichen",
        "PP!Passphrase" : "Passphrase (mindestens 20 Zeichen)",
        "PP!OrPassphrase" : "Passphrase (mindestens 20 Zeichen) oder",
        "Action!SetPassword" : "Passwort bestätigen",
        "Action!ConfirmAccount" : "Konto bestätigen",
        "TooltipSetPassword" : "Falsche E-Mail oder Passwörter sind nicht identisch.",
        "Action!EditProfile" : "Profil bearbeiten",
        "Action!SignOut" : "Abmelden",
        "Action!SignIn" : "Einloggen",
        "Update" : "Aktualisieren",
        "Error!PasswordChangeFailedInvalidPassword" : "Ihr altes Passwort ist nicht richtig. Bitte versuchen Sie erneut.",
        "Error!InvalidEmail" : "Bitte geben Sie Ihre E-Mail-Adresse ein.",
        "Error!InvalidPasswordReset" : "Ihr Token zum Zurücksetzen des Passworts war ungültig oder abgelaufen. Fordern Sie über den Link \"Passwort zurücksetzen\" ein neues an und versuchen Sie es nochmal.",
        "Error!InvalidCredentials" : "Ungültige E-Mail oder Passwort.",
        "Error!InvalidScopes" : "Ihre Zugriffsrechte gestatten die Verwendung dieser Anwendung nicht. Bitte wenden Sie sich an die Administration.",
        "Error!TemporarilyLockedOut" : "Die Anmeldung auf diesem Gerät ist aufgrund wiederholter Fehler vorübergehend blockiert. Wenn Sie Ihr Passwort vergessen haben, setzen Sie es über die Seite \"Passwort zurücksetzen\" zurück. Die Anmeldung wird in ${minutes} Minuten entsperrt.",
        "Error!TokenCheckFailed" : "Dieser Link ist möglicherweise abgelaufen.<br>Sie können Ihr Passwort auf der Anmeldeseite zurücksetzen.",
        "History" : "Geschichte",
        "Error!too_many_incorrect_email": "Zu viele falsche E-Mail-Adressen, bitte versuchen Sie es morgen erneut.",
        "PasswordRequirement" : "Ihr Passwort sollte mindestens 10 Zeichen lang sein und sowohl Groß- und Kleinbuchstaben, Zahlen als auch Sonderzeichen  enthalten.  Alternativ können Sie für Ihr Passwort mindestens 15 Zeichen verwenden. Verwenden Sie nicht mehr als zwei aufeinanderfolgende identische Zeichen.",
        "EmailRequired" : "E-Mail ist erforderlich",
        "EmailMustValid" : "E-Mail muss gültig sein",
        "PasswordMustValid" : "Passwort muss den Komplexitätsanforderungen entsprechen",
        "RepeatPasswordMustSame" : "Die Passwörter müssen identisch sein",
        "Youtube": "SignHero Youtube",
        "TitleNews" : "Nachrichten",
        "ReadMoreLink" : "Weiterlesen",
        "OtherNewsBtn" : "Weitere Nachrichten",
        "NewsListBg1" : "Damit haben wir uns beschäftigt",
        "NewsListBg2" : "Lesen Sie Nachrichten über unsere Erfolge, Veröffentlichungen und Erwähnungen in den Medien.",
        "Share" : "Teilen",
        "Locales": "Sprache",
    },
    "client": {
        "ClientSecretInfo": "Das Client-Geheimnis wird nur in dieser Sitzung angezeigt, notieren Sie es also zur späteren Verwendung. Fals Sie es möchten können Sie es in den späteren Sitzungen neu erzeugen.",
        "Error!InvalidDomain": "Ungültiger Domänenname.",
        "DomainValidationDownloadInfo1": "So verifizieren Sie Ihre Domain:",
        "DomainValidationDownloadInfo2": "Laden Sie die folgende Datei herunter:",
        "DomainValidationDownloadInfo3": "Laden Sie die Datei in das Stammverzeichnis Ihrer Domain hoch:",
        "DomainValidationDownloadInfo4": "Stellen Sie sicher, dass über den Link auf die Datei zugegriffen werden kann:",
        "DomainValidationDownloadInfo5": "Kommen Sie zurück auf seine Seite und klicken Sie auf \"Domäne verifizieren\"",
        "LastClientSecretReset": "Zuletzt geändert",
        "FailedDomainValidation": "Validierung Ihrer Domain fehlgeschlagen. Haben Sie die Anweisungen befolgt?",
        "OAuthApplications": "OAuth2 Anwendungen",
        "Application": "OAuth2 Anwendung",
        "IntegrateWithOAuth": "Integration mit OAuth2",
        "NewOAuthApplication": "Neue OAuth2 Anwendung",
        "ApplicationName": "Anwendungsname *",
        "AddOAuthApplication": "Anwendung hinzufügen",
        "AddUri": "Fügen Sie weitere URIs hinzu",
        "CreationDate": "Erstellungsdatum",
        "CreatedBy": "Erstellt von",
        "AllowedGrantTypes": "Zulässige Zugriffsarten",
        "OAuthRedirectURIs": "Umleitungs URIs *",
        "OAuthClientsDomainURI": "Domäne",
        "OAuthClientsDomainTitle": "Anwendungsdomäne *",
        "AppDescription": "Anwendungsbeschreibung",
        "VerifyDomain": "Domäne verifizieren",
        "DomainVerified": "Die Domäne wurde verifiziert.",
        "WhyValidateDomain": "Sie müssen den Besitz Ihrer Domain bestätigen. Die Anwendung kann nicht vor einer erfolgreicher Domain-Verifizierung verwendet werden. Einzelheiten finden Sie in der<a class='paragraph-links' href='/docs/api' target='_blank'>API Dokumentation</a>.",
        "ClientSecret": "Client-Geheimnis",
        "ClientId": "Client ID",
        "ClientCredentials": "Client-Anmeldeinformationen",
        "VerifiedApp": "Aktiv",
        "UnverifiedApp": "Inaktiv",
        "ResetClientSecret": "Geheimnis ändern",
        "ConfirmDeleteApiClient": "Möchten Sie diese Anwendung wirklich löschen?\nUm fortzufahren, geben Sie bitte diesen Bestätigungscode ein: ${rndCode}",
        "ConfirmResetClientSecret": "Möchten Sie das Client-Geheimnis wirklich neu erzeugen?\nUm fortzufahren, geben Sie bitte diesen Bestätigungscode ein: ${rndCode}",
        "AppHasBeenDeleted": "Die Anwendung ${appName} wurde gelöscht.",
        "AppHasBeenCreated": "Die Anwendung ${appName} wurde erstellt.",
        "YourChangesHaveBeenSaved": "Ihre Änderungen wurden gespeichert.",
        "ClientSecretChanged": "Client-Geheimnis neu erzeugt.",
        "CheckApiDocs": "Sie müssen den Besitz Ihrer Domain bestätigen, bevor die Anwendung verwendet werden kann. Einzelheiten finden Sie in der <a href='/docs/api' target='_blank'>API Dokumentation</a>."
    },
    "oauth2consent": {
        "AllowAccess": "Zugriff erlauben",
        "OAuthAccessDescriptionHeader": "<span class='elem-pink'>${appName}</span> benötigt Ihre Genehmigung, bevor Sie es verwenden können, um:",
        "OAuthBasicPermissions": "  <li>Neue und erinnerungs Unterzeichnungseinladungen zu senden</li>" +
                                 "  <li>Zugreifen, stornieren und löschen von der Anwendung gesendeten Unterschriftseinladungen</li>" +
                                 "  <li>Zugreif auf die unterzeichneten Dokumente die zu den Unterschriftseinladungen gehören die von der Anwendung gesendet wurden</li>",
        "OAuthSigningUrlPermissions": " <li>Sie dazu bringen, mit ihr hochgeladene Dokumente zu unterzeichnen</li>",
        "OAuthProfilePermissions": " <li>Zugreif uf Ihren Namen, Ihre E-Mail-Adresse, Ihren Firmennamen, Ihren Organisationsnamen und Ihre ausgewählte " +
                                    "Kommunikationssprache und prüfen ob Sie über Administratorrechte verfügen</li>",
        "OAuthAllDataPermissions": " <li>Access, cancel and delete all of your signing invitations</li>" +
                                   " <li>Access all of your documents</li>",
        "OAuthAccessWarning": "Dies bedeutet auch, dass Sie den Nutzungsbedingungen und der Datenschutzbestimmungen von ${appName} zustimmen.",
        "QuestionToAllowAccess": "Erlauben Sie <span class='elem-pink'>${appName}</span> den Zugriff auf Ihr SignHero-Konto  " +
                                 "<span class='elem-bold'>${userEmail}</span>?<br><br>Sie können dies jederzeit in Ihren SignHero-Kontoeinstellungen ändern. ",
        "Cancel": "Stornieren",
        "Allow": "Zulassen",
        "NotFound": "Entschuldigung, Anwendung nicht gefunden",
        "OAuthResourceNotFound": "Leider muss die Anwendung, die Sie hierher verwiesen hat, das Problem beheben."
    }
}
