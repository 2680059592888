export default {
    "pdfsign": {
        "Error!Unexpected": "Ett oväntat fel uppstod. Försök igen senare.",
        "SelectSigner": "Välj signerare",
        "SignatureTimestampFormat": "Signerad dd.mm.yyyy",
        "SignatureTypeFormat": "Typ: {Signature-type-xxxxxxxx}",
        "SignatureAdvancedLevelFormat": "Avancerad elektronisk signatur",
        "DeclinationPlaceHolder": "Orsaker till nekandet (valfritt)",
        "SignerGreetings": "Hej ",
        "WhoSigns": "Vem signerar:",
        "Sign": "Signera",
        "WhatSigned": "Vad blir signerat: <span class='sh-nowOrNeverText'>(klicka dokumentet för att granska – det är nu eller aldrig!)</span>",
        "SignConfirmationMsg": "Jag har granskat dokumenten samt har laglig rätt att signera.",
        "UsagePolicyInfoMsg": "Genom att använda SignHero godkänner du våra <a href='terms-of-use.html' target='_blank' style='color: #ff507a;'>användarvillkor</a> och <a href='privacy-policy.html' target='_blank' style='color: #ff507a;'>sekretesspolicy</a>.",
        "Decline": "Avböj",
        "Cancel": "Avbryt",
        "ThankUsingService": "Tack ändå!",
        "FindMoreInformation": "Välj smidighet på <a class='elem-pink' href='https://www.signhero.io'>SignHero.io</a>",
        "SignEventCancelled": "Signeringsprocessen har avbrutits",
        "CompleteProcessInfo": "Du kan inte signera dokumenten för signeringsprocessen är stängd. Du kan redan ha signerat dokumenten eller så avbröt avsändaren processen.",
        "DeclineToSignTitle": "Avböj att signera",
        "SignedAndSent": "Signerandet av dokumenten lyckades.",
        "SorryUnexpectedError": "Ursäkta, nånting gick snett",
        "SessionNeedRefreshError": "Din session måste uppdateras. Försök en gång till.",
        "ReasonExpiredSession": "Länken du följde kan vara felaktig eller så har inbjudan tagits bort.",
        "InvalidLoginUrl": "Kontrollera att den angivna webbadressen matchar länken i ditt e-postmeddelande och försök igen.",
        "You": "du",
        "AdvancedSign": "Signera",
        "chooseMethodDialogTitle": "Gå igenom dokumenten noggrant innan du signerar"
    },
    "signedin": {
        "locale!fi": "Suomi",
        "locale!en": "English",
        "locale!pl": "Polski",
        "locale!de" : "Deutsch",
        "locale!sv" : "Svenska",
        "locale!es" : "Español",
        "locale!fr" : "Français",
        "locale!nl" : "Nederlands",
        "locale!th" : "ภาษาไทย",
        "Applications": "Applikationer",
        "SearchUsers": "Sök användare",
        "UserDeleted" : "Användaren är inaktiverad och kommer att raderas permanent om 30 dagar",
        "UserRestored" : "Användaren har återställts",
        "UserAlreadyActive" : "Användaren är redan aktiv.",
        "OrganizationHasBeenDeleted": "En administratör har precis tagit bort din organisation.\nVi beklagar att ni avslutar ert konto och hoppas att vi ses igen snart.",
        "SendReminder": "Skicka aktiveringspåminnelse",
        "EmailReminderSuccess": "E-postpåminnelse har skickats.",
        "AdminStatusChanged": "Användaren uppdaterad.",
        "DateTimeFormat": "dd.MM.yyyy, HH:mm:ss",
        "SessionType!password": "Lösenord",
        "SessionType!auth_code": "Applikation",
        "Start": "Skapa ny",
        "TheService": "Administrering",
        "Save": "Spara",
        "MyOrganization": "Min organisation",
        "OrganizationName": "Organisations namn",
        "ManageAccounts": "Hantera konton",
        "ManageSignatureTypes" : "Hantera signaturtyper",
        "ManageSignatureTypesHelp" : "Välj de signaturtyper som din organisation kan använda. En måste vara standard.",
        "MyIntegrations": "Integrationer",
        "Statistics" : "Statistik",
        "isAdmin": "Admin",
        "DeleteUser": "Ta bort användarkonto",
        "RestoreAccount": "Återställ användarkonto",
        "Error!Unexpected": "Ett oväntat fel uppstod. Försök igen senare.",
        "Error!InvitedUserExists": "Användaren finns redan i den här organisationen eller tillhör en annan organisation. I det senare fallet, var god och kontakta ${email}",
        "InviteUser": "Bjud in",
        "UserInvited" : "Användaren är inbjuden",
        "Create": "Skapa",
        "ConfirmDeleteEverything": "Är du säker på att du vill ta bort detta organisationskonto (${groupTitle})?\n",
        "ConfirmCancelOngoingProcesses" : "Din organisation har ${processNum} pågående signeringsprocesser. De måste avbrytas innan du fortsätter med raderingen av organisationskontot.\n "
                                          + "\nÄr du säker att du vill avbryta alla pågående signeringsprocesser och ta bort detta organisationskonto (${groupTitle})?\n",
        "IncorrectConfirmationCode" : "Felaktig bekräftelsekod.",
        "DownloadOrganizationDataMessage": "Ladda ner användar- och signeringsprocessdata som en JSON-fil.",
        "DownloadOrganizationDataLink": "Ladda ner organisationsdata",
        "Subscription": "Abonnemang",
        "InviteUserHeader": "Bjud in en ny användare till din organisation",
        "AccountStatus" : "Kontots status:",
        "OwnAccount" : "Ditt eget konto",
        "StatusActive" : "Aktivt, användaren har accepterat inbjudan.",
        "StatusInactive" : "Inaktivt, användaren har inte accepterat inbjudan ännu.",
        "StatusDeleted" : "Kommer att raderas. Abonnemanget är inaktivt, det förnyas inte för den här användaren.",
        "AccountRights" : "Rättigheter:",
        "DownloadOrganizationData": "Ladda ner JSON",
        "DeleteOrganizationMessage": "Ta bort den här organisationen, ditt konto och alla andra administratörs- och användarkonton",
        "DeleteEverything": "Ta bort allt",
        "OrganizationHasActiveProcesses" : "Det gick inte att ta bort organisationen eftersom den har aktiva signaturprocesser eller sammanslagingsförfråganden.",
        "MergeOrganizationMessage": "Organisationen kan begära att slås samman med en annan.",
        "RequestMergeDetails": "Organisationens administratör kan begära att slå samman organisationen med en annan. Begäran kommer att vara giltig i 24 timmar.\n\n"
            + "Efter godkännande av begäran kommer signeringsprocesser inklusive dokument och användare att migreras till den nya organisationen, och denna organisation kommer att tas bort. "
            + "Användare av denna organisation kommer inte att ha några administratörsrättigheter efter sammanslagningen. Rättigheterna måste läggas till av den nya organisationens administratör. "
            + "Om det fanns en aktiv prenumeration kommer den återstående prenumerationsperioden att avbrytas, och sammanslagna användare kommer att räknas till den nya organisationens prenumeration.",
        "MergeOrganizationDetails": "Organisationen ${groupName} har begärt att få sammanslå sitt organisationskonto med ditt organisationskonto. "
            + "Begäran är giltig i 24 timmar.\n\n"
            + "Efter godkännande av begäran kommer signeringsprocesser inklusive dokument och användare att migreras till din organisation, "
            + "och den migrerade organisationen kommer att försvinna. Användare av den migrerade organisationen kommer inte att ha administratörsrättigheter efter sammanslagningen. "
            + "Sådana rättigheter måste läggas till av din organisations administratör. Om det fanns en aktivt prenumeration, "
            + "kommer de migrerade användarna att räknas till din organisations prenumeration.",
        "RequestMerge": "Begär kontosammanslagning",
        "ConfirmMergeOrganisation": "Är du säker på att du vill slå samman detta organisationskonto? Det går inte att dela upp kontona igen efter sammanslagningen.",
        "EnterAdminEmail": "Ange en administratörs e-postadress för den andra organisationen:",
        "EnterPassword": "För att fortsätta, ange ditt lösenord:",
        "InvalidPassword" : "Ditt lösenord var felaktigt. Var god försök igen.",
        "MergeRequestSent": "Begäran om sammanslagning av organisationen har skickats",
        "MergeRequestCanceled": "Organisationssammanslagningen avbröts",
        "MergeRequestRejected": "Organisationssammanslagningen avvisades",
        "ReceivedMergeRequests": "Inkomna sammanslagningsförfrågningar",
        "SentMergeRequest": "Skickad sammanslagningsförfrågan",
        "Recipient": "Mottagare",
        "Reject": "Avvisa",
        "Merge": "Slå samman",
        "OrganisationMerged": "Sammanfogningen startade.",
        "UserNotDifferentOrganizationAdmin": "Användaren är inte administratör den andra organisationen",
        "UserNotFound": "Tyvärr, användaren hittades inte!",
        "OrganizationHasActiveMergeRequest": "Organisationen har redan en aktiv sammanslagningsbegäran",
        "InvalidMergeRequest": "Sammanslagningsbegäran hittades inte eller så har den löpt ut",
        "Complete": "Arkiv",
        "Locales": "Språk",
        "Sessions": "Sessioner",
        "LogOutAllDevicesMessage": "Om du vill kan du logga ut från alla dina sessioner på alla enheter.",
        "NoApplicationsMessage": "Du har inte beviljat åtkomst till någon applikation än.",
        "Domain" : "Domän",
        "AccessGiven" : "Tillträde givet",
        "LastAccess" : "Senaste åtkomst",
        "RemoveAccess": "Ta bort åtkomst",
        "Ongoing": "Pågående",
        "MyAccount": "Konto",
        "UserName": "Namn",
        "Email": "E-post",
        "Logout": "Logga ut",
        "SignOut": "Logga ut från alla sessioner",
        "Confirm": "Bekräfta",
        "Cancel": "Avbryt",
        "Reset": "Återställ",
        "Profile": "Min profil",
        "Organization": "Organisation",
        "Device": "Enhet",
        "Update" : "Spara",
        "Delete" : "Ta bort",
        "AcceptApiTerms" : "Genom att använda SignHero API, godkänner du <a href='/terms/api' target='_blank'>API användarvillkoren</a>.",
        "CannotDeleteActiveFlows" : "Din organisation har pågående signeringsprocesser. Du måste avbryta dem först innan du fortsätter med radering av organisationskontot.",
        "MySubscription" : "Mitt abonnemang",
        "FreeTrialLeft" : "Gratistid kvar: ",
        "Days" : "dagar.",
        "SubscriptionPeriod" : "Intervallet för nuvarande abonnemang:",
        "PleaseActivateSubscription" : "Vänligen aktivera ditt abonnemang.",
        "Tab1" : "Betalningsmetod",
        "Tab2" : "Faktureringshistorik",
        "Tab3" : "Info",
        "NoPaymentNotification" : "Notera: Du har ingen giltig betalningsmetod.",
        "AddCreditCard" : "Lägg till ett kreditkort för SignHeros månadsfakturering",
        "CreditCardEmail" : "E-post",
        "BillingDate" : "Faktureringsdatum",
        "Amount" : "Belopp",
        "UsersAmount" : "Användare",
        "Duration" : "Giltighetstid",
        "ReferNumber" : "Referensnummer",
        "Invoice" : "Faktura",
        "Action" : "Handling",
        "SalesChannel" : "Försäljningskanal",
        "ResellerName" : "Återförsäljare: ",
        "ResellerContact" : "Kontakt: ",
        "ResellerMaxUsers" : "Max användare: ",
        "ResellerCurrentInvitedUsers" : "Nuvarande inbjuda användar: ",
        "MaxResellerUsersWarning" : "Antalet användare har nått maxgränsen (${resellerMaxUsers}) som din återförsäljare har angett.",
        "LoadMore" : "Ladda fler",
        "StartOfSubscription" : "Startdatum för abonemanget:",
        "EstimatedBilling" : "Nästa fakturering sker ${nextBillingDate} för beloppet ${nextBillingValue}€",
        "SubInactive1" : "Abonemanget är inaktivt",
        "SubInactive2" : ", det förnyas inte för den borttagna användaren",
        "isSubscriptionAdmin" : "Abonnemang admin",
        "CardInfo" : "Kreditkortsinformation",
        "NameOnCard" : "Namn på kreditkortet",
        "SelectCountry" : "Land eller region",
        "SelectLocale" : "Välj ett språk",
        "BusinessAccount" : "Företagskonto",
        "OnlyBusinessAccountsSupported" : "Endast företagskonton stöds för det valda landet.",
        "TaxIdExplanation" : "Välj din skatteregion och ange företagets VAT-nummer.",
        "SelectIdType" : "Välj typ av skatt-ID",
        "TaxId" : "Skatt-ID",
        "AddCardTooltip1" : "Lägg till giltig faktureringsinformation först.",
        "AddCardTooltip2" : "Vänligen lägg till giltig kreditkortsinformation. Alla fält är obligatoriska.",
        "AddCardTooltip3" : "Kreditkortsinformationen är giltig.",
        "BillingInformation" : "Faktureringsinformation",
        "Edit" : "Redigera",
        "EditBillingInformation" : "Redigera faktureringsinformationen.",
        "AddBillingTooltip" : "Vänligen fyll i alla obligatoriska fält och kontrollera att skatte-IDt matchar det valda landet.",
        "Street" : "Adress",
        "Street2" : "Adress2",
        "City" : "Stad",
        "State" : "Stat",
        "Zip" : "ZIP/Postkod",
        "SaveBillingInfo" : "Spara faktureringsinformationen",
        "SaveCard" : "Spara kreditkortsinformationen",
        "Expires" : "Giltigt t.o.m",
        "DeleteCardOnly" : "Ta bort kreditkortet",
        "DeletePaymentInfo" : "Ta bort både faktureringsinformationen och kreditkortet",
        "RemoveWholePaymentMethod" : "Ta bort allt",
        "PayOnline" : "Bekräfta betalning",
        "PaymentStatusPaid" : "Betald",
        "PaymentStatusPending" : "Väntar",
        "PaymentInfo" : "Om betalning",
        "PaymentExplanation1" : "SignHero kostar 5€ per månad per aktiv användare (Priset inkluderar inte moms). Vid tidpunkten gällande moms läggs till på priset. Inaktiva användare räknas inte med på fakturan. Om du lägger till eller tar bort användare under en faktureringsperiod kommer vi automatiskt att beräkna hur mycket mer eller mindre nästa faktura blir baserat på hur många dagar som återstår av faktureringsperioden. När du registrerar dig ger vi dig en 14 dagars gratis provtid, varefter du måste registrera ett kreditkort för månatliga återkommande betalningar om du vill fortsätta använda tjänsten. Ditt kreditkort debiteras automatiskt varje månad beroende på hur många användare du har registrerat i tjänsten. Du kommer att få ett kvitto på dina betalningar skickat till den e-postadress du angav när du registrerade ditt kreditkort.",
        "PaymentExplanation2" : "Du måste ange ditt företags momsnummer eftersom SignHero kommer att lägga till moms för kunder enligt finsk och EU:s skattelagstiftning. För företag inom EU måste ett giltigt momsregistreringsnummer anges. Om ditt land inte finns med i valet av land eller region kan du kontakta oss för alternativa betalningsmetoder. Vänligen meddela oss åtminstone om du inte hittade ditt land och beslutade dig för att inte använda tjänsten av den anledningen. Vi kan lägga till det i framtiden och kan meddela dig!",
        "PaymentExplanation3" : "Om du inte har möjlighet att lägga till ett kreditkort för automatisk fakturering, vänligen kontakta SignHero på <a class=='elem-pink' href='mailto:hello@signhero.io'>hello@signhero.io</a>.",
        "PaymentExplanation4" : "Har du problem med din faktura? Frågor om hur servicen fungerar? Vänligen kontakta oss på <a class=='elem-pink' href='mailto:hello@signhero.io'>hello@signhero.io</a> angående allt du undrar över.",
        "CountersPending" : "Statistiken håller på att beräknas. Försök igen efter några minuter.",
        "WholeOrganisation" : "Hela organisationen",
        "OrganisationHas" : "Din organisation har för närvarande",
        "ActiveUsers" : "aktiva användare och",
        "InactiveUsers" : "inaktiva användare.",
        "ChartDescriptionOrg" : "Diagrammet nedan visar skapade signaturprocesser och dokument för hela organisationen.",
        "ChartDescriptionUser" : "Tabellen nedan visar skapade signaturprocesser för de valda användarna.",
        "SignaturesCreated":"signaturer skapade",
        "AllSignatureHas":"I den valda tidsperioden är totala mängden signaturer ",
        "AdvancedSignatureHas":"och mängden avancerade är ",
        "AllSignature" : "Alla signaturer",
        "ChartDescriptionSignature" : "Tabellen nedan visar signaturer skapade i hela organisationen.",
        "SelectSignatureTypes" : " Välj signaturtyper",
        "AllSignatureInPeriod" : " Alla signaturer i tidsperioden",
        "SelectUsers" : "Välj användare",
        "From" : "Från",
        "To" : "Till",
        "AmountOfDocuments" : "Under den valda tidsperioden är antalet nya dokument",
        "AmountOfSignatureProcesses" : "och antalet nya signaturprocesser är",
        "Processes" : "Processer",
        "Documents" : "Dokument",
        "Users" : "Användare",
        "UserActivity" : "Användaraktivitet",
        "Signatures": "Signaturer",
        "User" : "Användare",
        "Inactive" : "Inaktiv",
        "SignatureProcesses" : "Signaturprocesser i tidsperioden",
        "AllSignatureProcesses" : "Alla signaturprocesser",
        "DownloadSignatureStatsMessage": "Ladda ner signaturstatistiken som CSV-fil.",
        "DownloadSignatureStatsLink": "Ladda ner signaturstatistik",
        "FreeTrialAlert1" : "Din organisation har",
        "FreeTrialAlert2" : "dagar gratistid kvar!",
        "ContactAdmin" : "Ingen aktiv prenumeration, kontakta din administratör!",
        "PaymentOverdueAlert" : "Betalning försenad, lägg till ett giltigt kort",
        "PaymentOverdueAlertIgnore" : "Om du redan har lagt till ett giltigt kort, ignorera detta meddelande",
        "PleaseAddValidCPayment" : "Ingen aktiv prenumeration, lägg till en giltig betalningsmetod!",
        "PleaseConfirmPayment" : "Kortautentisering krävs. Kontrollera \"Faktureringshistorik\"!",
        "PleaseConfirmPaymentMainPage" : "Ingen giltig betalningsmetod eller kortautentisering krävs. Kontrollera \"Min prenumeration\"!",
        "ConfirmDeletePaymentMethod": "Är du säker på att du vill radera fakturerings- och kreditkortsinformation?",
        "ConsentDialogTitle": "Prenumerera på SignHero för återkommande betalningar?",
        "ConsentText1": "SignHero är smidig e-signering. Skicka & signera PDF-filer, spåra processer, påminn signerare, få tillgång till revisionsspår, arbeta i team och arkivera signerade dokument.",
        "ConsentText2": "SignHero kostar 5€ per månad per aktiv användare + moms. Inaktiva användare räknas inte in i din faktura. Om du lägger till eller tar bort användare under en faktureringscykel kommer vi automatiskt att beräkna hur mycket mindre eller mer nästa faktura blir, baserat på hur mycket av faktureringscykeln som återstår när ändringen gjordes. När du registrerar dig ger vi dig en 14 dagars gratis provtid, varefter du måste registrera ett kreditkort för månatliga återkommande betalningar om du vill fortsätta använda tjänsten. Ditt kreditkort debiteras automatiskt samma datum varje månad och summan är beroende på hur många användare du har registrerat i tjänsten. Efter att du har registrerat ditt kreditkort kommer din prenumeration att vara giltig efter framgångsrik betalning och förbli giltig. Dessutom kommer du att få dessa e-postmeddelanden:",
        "ConsentText3": "Du måste ange ditt företags momsnummer eftersom SignHero kommer att ta ut moms från kunder enligt finsk och EU:s skattelagstiftning. För företag inom EU måste ett giltigt momsregistreringsnummer anges. Om ditt land inte är listat i valet av land eller region kan du kontakta oss för alternativa betalningsmetoder. Vänligen meddela oss åtminstone om du inte hittade ditt land och beslutade att inte använda tjänsten av den anledningen. Vi kan lägga till det i framtiden och meddela dig!",
        "ConsentText4": "För att avbryta prenumerationen kan du ta bort ditt kreditkort. Den aktiva prenumerationen kommer att avbrytas vid slutet av prenumerationsperioden. Alternativt kan du avbryta prenumerationen direkt genom att klicka på knappen Avbryt prenumerationen.",
        "ConsentEmailBullet1": "ett e-postmeddelande som påminnelse om att provperioden tar slut",
        "ConsentEmailBullet2": "ett kvitto på din månatliga betalning till den e-postadress du angett",
        "ConsentEmailBullet3": "ett e-postmeddelande 7 dagar före varje korttransaktion",
        "ConsentCheckBoxLabel1": "Jag har läst ovanstående information",
        "ConsentCheckBoxLabel2": "Jag samtycker till att prenumerera på SignHero prenumerationstjänst för återkommande betalningar",
        "Agree": "Jag samtycker",
        "CurrentPlan" : "Nuvarande prenumeration",
        "SignHeroSubscription" : "SignHero-prenumeration",
        "CancelPlan" : "Avbryt prenumerationen",
        "CreatePlan" : "Skapa prenumeration",
        "PriceInfo" : "5€ per månad per aktiv användare",
        "TaxTooltip" : "Observera att priset inte inkluderar moms.",
        "PleaseAddValidCard" : "Vänligen lägg till en giltig betalningsmetod",
        "CancelPlanConfirm" : "Är du säker på att du vill avbryta nuvarande prenumeration?\nDu kommer att gå miste om provperioden eller förlora den återstående tiden på ditt betalda abonnemang. Återstående tid kompenceras ej.",
        "CreatePlanConfirm" : "Är du säker på att du vill skapa en ny prenumeration?",
        "numbersDidNotMatch" : "Koderna stämmer inte överens. Var god försök igen.",
        "toProceedEnterCode" : "För att fortsätta, ange koden: ",
        "subStatus!trialing" : "Provtiden tar slut ",
        "subStatus!active" : "Aktiv",
        "subStatus!canceled" : "Avbruten",
        "subStatus!incomplete" : "Ofullständig",
        "subStatus!incomplete_expired" : "Utgången",
        "subStatus!past_due" : "Försenad",
        "ConfirmCreateSubNow" : "Du kan skapa en ny prenumeration nu eller senare från Mitt abonnemang.",
        "CreateSubscriptionNow" : "Skapa nu",
        "CreateSubscriptionLater" : "Senare",
        "Country!Australia" : "Australien",
        "Country!Austria" : "Österrike",
        "Country!Belgium" : "Belgien",
        "Country!Brazil" : "Brasilien",
        "Country!Bulgaria" : "Bulgarien",
        "Country!Canada" : "Kanada",
        "Country!Chile" : "Chile",
        "Country!Croatia" : "Kroatien",
        "Country!Cyprus" : "Cypern",
        "Country!CzechRepublic" : "Tjeckien",
        "Country!Denmark" : "Danmark",
        "Country!Estonia" : "Estland",
        "Country!Finland" : "Finland",
        "Country!France" : "Frankrike",
        "Country!Germany" : "Tyskland",
        "Country!Greece" : "Grekland",
        "Country!HongKong" : "Hong Kong",
        "Country!Hungary" : "Ungern",
        "Country!India" : "Indien",
        "Country!Indonesia" : "Indonesien",
        "Country!Ireland" : "Irland",
        "Country!Israel" : "Israel",
        "Country!Italy" : "Italien",
        "Country!Japan" : "Japan",
        "Country!Korea" : "Korea",
        "Country!Latvia" : "Lettland",
        "Country!Liechtenstein" : "Liechtenstein",
        "Country!Lithuania" : "Litauen",
        "Country!Luxembourg" : "Luxembourg",
        "Country!Malaysia" : "Malaysia",
        "Country!Malta" : "Malta",
        "Country!Mexico" : "Mexiko",
        "Country!Netherlands" : "Nederländerna",
        "Country!NewZealand" : "Nya Zeeland",
        "Country!Norway" : "Norge",
        "Country!Poland" : "Polen",
        "Country!Portugal" : "Portugal",
        "Country!Romania" : "Rumänien",
        "Country!RussianFederation" : "Ryska federationen",
        "Country!SaudiArabia" : "Saudiarabien",
        "Country!Singapore" : "Singapore",
        "Country!Slovakia" : "Slovakien",
        "Country!Slovenia" : "Slovenien",
        "Country!SouthAfrica" : "Sydafrica",
        "Country!Spain" : "Spanien",
        "Country!Sweden" : "Sverige",
        "Country!Switzerland" : "Schweiz",
        "Country!Taiwan" : "Taiwan",
        "Country!Thailand" : "Thailand",
        "Country!UnitedArabEmirates" : "Förenade Arabemiraten",
        "Country!UnitedKingdom" : "Storbritannien",
        "Country!UnitedStates" : "USA",
        "TaxType!au_abn" : "Australiskt företagsnummer (AU ABN)",
        "TaxType!au_arn" : "Referensnummer för det australiska skattekontoret",
        "TaxType!eu_vat" : "Europeiskt momsnummer",
        "TaxType!br_cnpj" : "Brasilianskt CNPJ-nummer",
        "TaxType!br_cpf" : "Brasilianskt CPF-nummer",
        "TaxType!ca_bn" : "Kanadensiskt BN",
        "TaxType!ca_gst_hst" : "Kanadensiskt GST/HST-nummer",
        "TaxType!ca_pst_bc" : "Kanadensiskt PST-nummer (British Columbia)",
        "TaxType!ca_pst_mb" : "Kanadensiskt PST-nummer (Manitoba)",
        "TaxType!ca_pst_sk" : "Kanadensiskt PST-nummer (Saskatchewan)",
        "TaxType!ca_qst" : "Kanadensiskt QST-nummer (Québec)",
        "TaxType!cl_tin" : "Chileansk TIN",
        "TaxType!hk_br" : "Hong Kong BR-nummer",
        "TaxType!in_gst" : "Indisk GST-nummer",
        "TaxType!id_npwp" : "Indonesiskt NPWP-nummer",
        "TaxType!il_vat" : "Israelisk moms",
        "TaxType!jp_cn" : "Japanskt företagsnummer",
        "TaxType!jp_rn" : "I Japan registrerade utländska företags registreringsnummer",
        "TaxType!kr_brn" : "Koreanskt BRN",
        "TaxType!li_uid" : "Liechtensteinskt UID-nummer",
        "TaxType!my_frp" : "Malaysiskt FRP-nummer",
        "TaxType!my_itn" : "Malaysiskt ITN",
        "TaxType!my_sst" : "Malaysiskt SST number",
        "TaxType!mx_rfc" : "Mexikanskt RFC-nummer",
        "TaxType!nz_gst" : "Nyzeeländskt GST-nummer",
        "TaxType!no_vat" : "Norskt momsnummer",
        "TaxType!ru_inn" : "Ryskt INN",
        "TaxType!ru_kpp" : "Rysk KPP",
        "TaxType!sa_vat" : "Saudiarabisk moms",
        "TaxType!sg_gst" : "Singaporeansk GST",
        "TaxType!sg_uen" : "Singaporeansk UEN",
        "TaxType!za_vat" : "Sydafrikanskt momsnummer",
        "TaxType!es_cif" : "Spanskt CIF-nummer",
        "TaxType!ch_vat" : "Schweiziskt momsnummer",
        "TaxType!tw_vat" : "Taiwanesisk moms",
        "TaxType!th_vat" : "Thailändsk moms",
        "TaxType!ae_trn" : "Förenade Arabemiraten TRN",
        "TaxType!gb_vat" : "Brittiskt momsnummer",
        "TaxType!us_ein" : "USA EIN",
        "SignatureTypes" : "Signaturtyper",
        "SignatureDefault" : "Standard",
        "SignatureSaved": "Dina ändringar har sparats.",
        "SignatureType!email-based-signature" : "Mejlbaserad SignHero-signatur",
        "SignatureType!smart-id-signature" : "Estnisk Smart-ID",
        "SignatureType!fi-ftn-advanced-signature" : "Finländsk nätbank och mobilcertifikat",
        "SignatureType!fi-ftn-intesi-adv-signature" : "Finländsk nätbank - Intesi",
        "SignatureTypeCountInPeriod!email-based-signature" : "Mejlbaserad SignHero-signatur i tidsperioden",
        "SignatureTypeCountInPeriod!smart-id-signature" : "Estnisk Smart-ID signatur i tidsperioden",
        "SignatureTypeCountInPeriod!fi-ftn-advanced-signature" : "Finländsk nätbank och mobilcertifikat signatur i tidsperioden",
        "SignatureTypeCountInPeriod!fi-ftn-intesi-adv-signature" : "Finländsk nätbank - Intesi signatur i tidsperioden"
    },
    "signflow": {
        "SendReminder": "Skicka aktiveringspåminnelse",
        "SessionType!password": "Lösenord",
        "SessionType!auth_code": "Applikation",
        "Start": "Starta ny",
        "Error!Unexpected": "Ett oväntat fel uppstod. Försök igen senare.",
        "ButtonBack": "Tillbaka",
        "ButtonGoBack": "Gå tillbaka",
        "ButtonNext": "Nästa",
        "Create": "Skapa",
        "Complete": "Arkiv",
        "SearchSignFlows": "Sök enligt signerare, titel eller filnamn",
        "AllFlows": "Alla",
        "PendingMeFlows": "Väntar på mig",
        "PendingOthersFlows": "Väntar på andra",
        "CanceledFlows": "Annullerade",
        "CompletedFlows": "Slutförda",
        "ShowGroupProcesses": "Alla organisationens processer",
        "CancellationReasons": "Orsak: ${cancellationReasons}",
        "Documents" : "Dokument:",
        "SignedDocuments" : "Signerade document:",
        "UnsignedDocuments" : "Osignerade dokument:",
        "GenerateDigests" : "Generera hash-koder",
        "DigestTitle" : "Hash-kod:",
        "FtnSignatureTitle" : "XML-DSig signatur (Finländsk nätbank och mobilcertifikat):",
        "FtnSignatureXmlFile" : "Dokument-signatur.xml",
        "SignatureValidationFilesDesc" : "Ifall av behov, använd dessa filer för att visa att signeraren hade för avsikt att signera.",
        "SignatureValidationFiles" : "Signaturvalideringsfiler",
        "CreatedByTitle": "Skapad av:",
        "CanceledByTitle": "Annullerad av:",
        "PendingSignaturesTitle": "Väntar på:",
        "PendingMeAlert": "<span class='elem-pink'Du</span> har inte signerat.",
        "PendingOthersAlert": "Väntar på att <span class='elem-pink'>${count}</span> person(er) skall signera.",
        "SignerStatusTitle": "Status:",
        "ReminderHistory": "Påminnelsehistorik",
        "SignerStatus1": "Väntar, inbjudan öppnades första gången ",
        "SignerStatus2": "Väntar, inbjudan har aldrig öppnats.",
        "SignerStatus3": "Signerad ",
        "SignerStatus4": "Väntar på skaparen.",
        "SignerStatus5": "Väntar.",
        "SendReminders": "Påminn via e-post",
        "NoProcessesFound": "Inga processer matchar dina filter",
        "LastEmailReminder": "Senaste påminnelse: <b>${timestamp}</b>",
        "NoRemindersYet": "Inga påmennelser skickade",
        "EmailReminderSuccess": "E-postpåminnelser skickade.",
        "ReminderSentBy": "skickades av",
        "CancelProcessDialogTitle": "Annullera processen",
        "CancellationReasonsPlaceHolder": "Orsaker till annulleringen",
        "CancelProcess": "Annullera processen",
        "DeleteProcess": "Radera processen",
        "RestartProcess": "Starta om",
        "ConfirmDeleteProcess": "Är du säker du vill redara processen?",
        "ResendDownloadInvitation": "Skicka nedladdningslänken pånytt",
        "DownloadInvitationEmailSuccess": "Email skickat.",
        "DownloadInvitationEmailFail": "Skickandet misslyckades",
        "Me": "Jag",
        "SignFlowDocumentsHeading": "Vad signeras?",
        "FlowSettings": "Extra",
        "FlowTitlePlaceholder": "Namnge processen för smidig arkivering",
        "Signers": "Signerare *",
        "AddSigner": "Lägg till fler signerare",
        "AddSignerGroup": "Lägg till signerare från fil",
        "AddressBookChooseFile": "Fälj fil",
        "AddressBookInfoHeader": "Hur man lägger till signerare från fil",
        "AddressBookInfo": "Det bör vara en fil av typen .txt eller .csv, med varje mottagere/signerare på skilld rad och med kommatecken mellan fälten.",
        "AddressBookInfoExampleHeader": "Till exempel en fil med namnet BoardMembers.txt med följande innehåll:",
        "AddressBookInfoExampleContent": "James Smith, james.smith@example.com<br>Mary Jones, mary.jones@example.com<br>John Williams, john.williams@example.com<br>Linda Taylor, linda.taylor@example.com",
        "NotAnAddressBook": "Filen innehöll inte mottagarnamn och adresser.",
        "CheckFileEncoding": "Filen innehåller tecken som inte stöds. Se till att filkodningen är UTF-8.",
        "SignersName": "För-och efternamn",
        "SignersEmail": "E-post",
        "EmailSubject": "E-postrubrik:",
        "EmailMessage": "Meddelande:",
        "EditInvitation": "Redigera signaturinbjudan",
        "ChooseInvitation": "Välj inbjudningsmall",
        "UseDragAndDropSignature": "Markera positionen för signaturerna",
        "StartNow": "Signera nu",
        "StartSigningNow": "Du kan signera nu eller senare från fliken Pågående.",
        "RequestEmailSendSigners": "Inbjudan skickas via e-post åt de som skall signera. ",
        "InformProgressNotifications": "Du får e-post om hur processen framskrider.",
        "Later": "Senare",
        "Ok" : "Ok",
        "DefaultEmailSubject": "${$cc_user_info.name} ber dig signera",
        "DefaultEmailMessage": "${$cc_user_info.name} från ${$cc_user_info.organization.display_name} (${$cc_user_info.email}) ber dig signera med SignHero.",
        "Signer": "Signerare",
        "Created": "Skapad",
        "Canceled": "Annullerad",
        "Sign": "Signera",
        "Send": "Skicka",
        "GoToSign": "Gå till signeringen",
        "Download": "Ladda ner",
        "SignedBy": "Signerad av:",
        "WhoIsSigning": "Vem signerar?",
        "OnlyMe": "Enbart jag",
        "OnlyOthers": "Enbart andra",
        "MeAndOthers": "Jag och andra",
        "Email": "E-post",
        "Add": "Lägg till",
        "numbersDidNotMatch": "Koderna stämmer inte överens. Var god försök igen.",
        "toProceedEnterCode": "För att fortsätta, ange koden: ",
        "Confirm": "Bekräfta",
        "Continue": "Fortsätt",
        "Cancel": "Avbryt",
        "Reset": "Återställ",
        "selectFromDisk": "Lokalt",
        "UploadFile": "Ladda up",
        "fileTooLarge": "Maximal filstorlek är ${maxSize}.",
        "allFilesTooLarge": "Den totala maximala storleken för alla filer är ${maxSize}. Du försöker nu ladda upp ${uploadedSize}.",
        "fileNumberTooLarge": "Ladda upp högst ${maxFileNumber} filer.",
        "uploadError": "Tyvärr, det är något fel med en fil. Kanske fel format eller så är den redan signerad.",
        "select": "Välj",
        "OngoingProcess" : "Pågående processer",
        "GoogleDrive": "Google Drive",
        "DownloadFailed": "Filnedladdning misslyckades",
        "Error!FilesOutdated" : "Sessionen har avslutats. Ladda upp filer och fyll i informationen igen ifall det behövs",
        "Error!DocumentError" : "Tyvärr, sessionen har löpt ut eller så är det nåt fel med en fil. Kanske fel format. Ladda upp filer och fyll i informationen igen ifall det behövs.",
        "OnlyPdf" : "Godkända filtyper är pdf",
        "OnlyCancelledProcessAllowed": "Du kan bara starta om en annullerad process.",
        "CreateNewTags" : "Skapa ny tagg",
        "CreateNewTagsHint" : "Tryck enter för att lägga till ny tagg: max 20 tecken",
        "ManageTags" : "Hantera taggar",
        "Tags" : "Taggar",
        "FilterByTag" : "Filtrera enligt tagg",
        "ErrorTagExists" : "Taggen finns redan!   ",
        "ContactAdmin" : "Ingen aktiv prenumeration, kontakta din administratör!",
        "PaymentOverdueAlert" : "Betalningen är försenad, lägg till ett giltigt kreditkort",
        "PaymentOverdueAlertIgnore" : "Om du redan har lagt till ett giltigt kreditkort kan du ignorera detta meddelande",
        "PleaseAddValidCPayment" : "Ingen aktiv prenumeration, lägg till en giltig betalningsmetod!",
        "PleaseConfirmPayment" : "Kreditkortsautentisering krävs. Kontrollera \"Faktureringshistorik\"!",
        "PleaseConfirmPaymentMainPage" : "Ingen giltig betalningsmetod eller så krävs kreditkortsautentisering. Kontrollera \"Mitt abonnemang\"!",
        "ConfirmDeletePaymentMethod": "Är du säker på att du vill ta bort fakturerings- och kreditkortsinformationen?",
        "OwnedBy" : "Skapad av:",
        "SavedTemplates" : "Välj inbjudningsmall",
        "CreateTemplateTitle" : "Spara inbjudningsmallen",
        "NameTemplate" : "Namnge inbjudningsmallen",
        "NameTemplateHint" : "Används endast för sökning och visas inte i inbjudningar.",
        "TemplateCreated" : "Inbjudningsmall sparad.",
        "DeleteTemplateTitle" : "Ta bort delad inbudningsmall",
        "DeleteTemplateConfirm" : "Är du säker? Detta påverkar hela organisationen.",
        "Update" : "Uppdatera",
        "Edit" : "Redigera",
        "Save": "Spara",
        "ExplainTemplate1" : "Organisationen har inga sparade inbjudningsmallar. Inbjudningsmallar kan sparas efter att du redigerat dem.",
        "ExplainTemplate2" : "Du kan spara - men du behöver inte! ",
        "ExplainTemplate3" : "Aktuell signaturinbjudan kan användas utan att spara.",
        "ExplainTemplate4" : "Du kan spara ofta använda inbjudningsmallar. De skapade mallarna är synliga för hela organisationen och kan redigeras eller tas bort av vem som helst i organisationen.\nAktuell signaturinbjudan kan användas utan att spara.",
        "UpdateTemplate" : "Mallen kommer att vara synlig, redigerbar och raderbar för alla i organisationen.\n\nMallnamn: ",
        "DeleteTemplate" : "Mallen raderad.",
        "TemplateNoname" : "noname",
        "Error!TemplateNameExist": "Mallen finns redan, ska den skrivas över?",
        "Error!TemplateNameEmpty": "Mallnamnet saknas!",
        "ProcessesTabName1" : "Mina processer",
        "ProcessesTabName2" : "Mina signeringsinbjudningar",
        "ShowOnly12MonthInfo" : "Visar processer från de senaste 12 månaderna",
        "MaxSigners" : "Maximalt antal undertecknare för en process är ",
        "MaxSigners1" : "Ta bort ",
        "MaxSigners2" : " undertecknare.",
        "MaxSigners3" : "Fler undertecknare kan inte läggas till.",
        "SelectSignatureTypes" : "Välj signaturtyper",
        "AllowedSignatureTypes" : "Tillåtna signaturtyper",
        "AllSigners" : "Alla signerare",
        "SwitchAdvancedSignature" : "Byt till avancerad signatur",
        "SwitchBasicSignature" : "Byt till mejlbaserad SignHero-signatur",
        "BasicLevel" : "Mejlbaserad SignHero-signatur",
        "AdvancedLevel" : "Avancerad signatur",
        "BasicLevelInfo" : "När mejlbaserad SignHero-signatur är vald ändras alla signerare till denna typ.",
        "AdvancedLevelInfo" : "När avancerad signatur är vald uppdateras alla signerare med de valda signaturtyperna.",
        "Apply" : "Spara"
    },
    "message": {
        "ReportProblem" : "Rapportera problem",
        "IForgotMyPassword" : "Återställ lösenord",
        "NavigationMenuButton": "Meny",
        "NavigationAccountButton": "Konto",
        "NavigationContactButton": "Kontakt",
        "NavItemIntroduction": "Introduktion",
        "NavItemChooseEasy": "Välj smidighet",
        "NavItemAccount": "Konto",
        "NavItemAPIsIntegrations": "APIn & Integrationer",
        "NavItemDoubtsWorries": "Tankar & Frågor",
        "NavItemThisIsUs": "Det här är vi",
        "NavItemContactUs": "Kontakta oss",
        "MainSlogan": "SignHero - Smidigare än så blir det inte!",
        "SubSloganPart1": "Ett uppror mot komplexitet, överkonstruerade processer",
        "SubSloganPart2": "och det gamla sättet att göra saker på.",
        "GoToRegister": "Prova gratis",
        "TitleLazy": "Välj smidighet",
        "TextLazyLine1": "Stämplar and underskrifter är irrelevanta;",
        "TextLazyLine2": "de representerar överkonstruerade processer som slösar tid och pengar.",
        "TextLazyLine3": "SignHero-användare vill fokusera på det som är relevant, så vi ger dig allt väsentligt.",
        "MetricsTitle": "Genomsnittlig veckostatistik:",
        "Metrics1" : "6000+ personer som använder SignHero",
        "Metrics2" : "2000+ kontrakt signerade",
        "Metrics3" : "100+ nya organisationskonton",
        "TitleAccount": "Konto",
        "TextAccount": "SignHero-kontot innehåller allt du verkligen behöver för att e-signera:",
        "Feature1": "Skicka & Signera PDF-filer",
        "Feature2": "Följ med signaturprocesser",
        "Feature3": "Påminn signerare",
        "Feature4": "Verifieringskedja",
        "Feature5": "Arbeta i team",
        "Feature6": "Dokumentarkiv",
        "UnlimitedSigners": "Obegränsat antal signerare och inbjudningar.",
        "EasyRegister": "Registrera dig med ditt namn, e-postadress och organisation.",
        "Register": "Skapa ett konto",
        "QuoteMarko": "\"We sign nearly all contracts with SignHero. Feedback has been really positive, and people keep asking how they can start using it too.\"",
        "AuthorMarko": "Marko Vihiniemi",
        "CompanyMarko": "CEO, Salon Vilpas Koripallo Oy",
        "QuoteHenri": "\"I was fascinated by the increased efficiency compared with old signing methods. Our whole team started using SignHero, and it has received lots of praise!\"",
        "AuthorHenri": "Henri Kämäräinen",
        "CompanyHenri": "Head of Partnerships, FallUp, Aalto Entreprenership Society",
        "QuoteJuho": "\"Usually I don’t believe things that seem too good to be true, but in this case, you get exactly what’s promised.\"",
        "AuthorJuho": "Juho Nenonen",
        "CompanyJuho": "Co-founder & CEO, Sportspot",
        "QuoteKarolina": "\"First, we tried this out of curiosity, but now, our legal advisers couldn’t imagine not using SignHero! We primarily eSign contracts for legal services and formal legal opinions.\"",
        "AuthorKarolina": "Karolina Bonarska-Lenarczyk",
        "CompanyKarolina": "Legal adviser",
        "QuoteMatias": "\"Less time spent on unproductive work, less paper, and a more secure way to sign. SignHero is a great solution!\"",
        "AuthorMatias": "Matias Vihko",
        "CompanyMatias": "Chairman of the Board, Koju Film Company",
        "QuoteKalle": "\"SignHero is very straightforward and eases property management work, which involves a lot of signing.\"",
        "AuthorKalle": "Kalle Grönqvist",
        "CompanyKalle": "CEO, Fluxio",
        "QuoteMonika": "\"We wanted a modern solution that makes life easier for our customers. SignHero improves the booking process and registration – leaving us more time for our furry guests.\"",
        "AuthorMonika": "Monika Jackowska-Ziemba",
        "CompanyMonika": "Co-owner, DOGStation",
        "QuoteDominika": "\"HR can finally sign paperlessly! SignHero supports our signing process and also conveniently archives all signed documents.\"",
        "AuthorDominika": "Dominika Pusz",
        "CompanyDominika": "President of the Board, MDJ Recruitment",
        "QuoteVisa": "\"I love SignHero!\"",
        "AuthorVisa": "Visa Tuovinen",
        "CompanyVisa": "Roast Master, Lehmus Roastery",
        "TitlePricing": "Prissättning",
        "MonthlyPrice": "5€ / användare / månad",
        "MonthlyBill": "(Faktureras månadsvis. Priset inkluderar inte mervärdesskatt/moms. Den  gällande momsen kommer att läggas till priserna.)",
        "Benefits1": "Obegränsat antal elektroniska signaturer",
        "Benefits2": "Arkiv för dina dokument",
        "Benefits3": "Teamkonto för flera användare",
        "Benefits4": "Signerare behöver inget konto",
        "ButtonFreeTrial": "Starta en 14-dagars provperiod",
        "CreditCardInfo": "Inget kreditkort behövs",
        "TitleAPI": "APIn & Integrationer",
        "IntegrateSignHero": "Integrera vår signeringsknapp var du än behöver.",
        "ToIntroBTN": "Intro",
        "ToDocsBTN": "API-manual",
        "ToGmailBTN": "Gmail",
        "ToZapierBTN": "Zapier",
        "QuoteOlli": "\"Integrating electronic contract generation and signing into a WordPress environment saves our clients tens of hours of time.\"",
        "AuthorOlli": "Olli Nyqvist",
        "CompanyOlli": "CEO, WebAula",
        "TitleDoubts": "Tankar & Frågor",
        "DoubtsLegal": "Är e-signaturer ens lagliga?",
        "TitleShortAnswer": "Kort svar:",
        "AnswerLegalParagraph1": "Klart det. De har redan funnits i mer än ett decennium.",
        "AnswerLegalParagraph2": "Att signera ett papper med en penna är inte ett särskilt säkert sätt att signera. Lagen är på vår sida; Ta en titt på",
        "eIDAS": "eIDAS",
        "ESIGN": "ESIGN",
        "And": "och",
        "Acts": "stadgarna.",
        "TitleLongAnswer": "Långt svar:",
        "LegalLongAnswerParagraph1": "Du vet, att skriva under ett papper är inte ett särskilt säkert sätt att ingå avtal.\nHandskrivna signaturer kan förfalskas, ifrågasättas och även gå förlorade. Faktum är\natt om du fortfarande klottrar med papper och penna, är du i princip inte säker på någonting.",
        "LegalLongAnswerParagraph2": "Dessutom råder avtalsfrihet inom EU, enligt vilken parterna kan välja hur avtal\nska bekräftas. Kontrollera att ditt företag inte har några specialkrav\n(t.ex. penningtvättslagen) och e-signera på sen.",
        "LegalLongAnswerParagraph3": "Autentisering är som försäkringar. Visade du till exempel legitimation när du skrev på ditt\nnuvarande arbetsavtal? Vissa avtal har låg risk och kräver därför endast lätt försäkring;\ni det här fallet räcker det med autentisering via e-post.",
        "LegalLongAnswerParagraph4": "SignHero används för närvarande för: Kommersiella kontrakt / Anställningsavtal / NDAn / Mötesprotokol\n/ Hyreskontrakt",
        "DoubtsPrice": "Hur fungerar det? Bara 5€?",
        "PriceShortAnswerParagraph1": "Du signera och arkivera dokument med några få klick. Signeringsinbjudningar skickas via e-post och för signering krävs inget konto. Priset är 5€ per användare per månad. Avgiften faktureras automatiskt varje månad när du har fyllt i dina kreditkortsuppgifter. Du kan avboka ditt abonnemang när som helst, så du betalar bara för en månad åt gången.",
        "PriceLongAnswerParagraph1": "SignHero låter dig signera PDF-dokument med dator och mobil, samt kan integreras i vilket CRM, digitalt arkiv eller annat dokumenthanteringssystem som helst via vårt API. Du kan till och med signera direkt i ditt Gmail-konto via vår SignHero plug-in utan att lämna din inkorg.",
        "PriceLongAnswerParagraph2": "<span class='doubts-break1'>Du kan skapa ett konto utan att binda dig och då får du 14-dagars gratis provtid. Ingen kreditkortsinformation behövs när du registrerar dig. Den kostnadsfria provtiden inkluderar alla funktioner, så du kan testa allt ordentligt. Och oroa dig inte, vi skickar aldrig skräppost</span> <span class='doubts-break2'>eller onödiga nyhetsbrev.</span>",
        "PriceLongAnswerParagraph3": "Priset är 5€ per användare och månad. Som betalande användare kan du skicka signaturförfrågningar, övervaka signeringshändelser, lagra dina dokument i SignHero-arkivet och arbeta som ett team i ett organisationskonto. Du kan lägga till nästan oändligt med team-medlemmar till ditt konto och varje ny användare ökar på kostnaden med 5€ på din månatliga prenumeration.",
        "PriceReasons1": "I nåt skede behöver SignHero kunna stå på egna ben och inte för alltid få stöd av ett kärleksfullt moderbolag (Avaintec).",
        "PriceReasons2": "Vi kan inte växa och skapa en bättre användarupplevelse för våra användare om vi inte får in lite pengar för att betala våra räkningar.",
        "PriceReasons3": "Varje vecka frågar nån oss hur vi utnyttjar deras data och lurar dem med en briljant och förklädd gratistjänst. Tja, inte längre. Vi är helt säkra på att när du börjar betala 5€ per månad kommer du att lita mer på oss. Vem skulle inte lita på en kapitalist?",
        "DoubtsSaveTime": "Vad kan jag göra med all tid jag sparar?",
        "SaveTimeAnswer": "Ta en kopp kaffe, åk ut och fiska, faktiskt inte, få lite arbete gjort, ring din mamma, betala din skatt, logga in dina timmar, återvinn, rädda världen, lek med din hund, lek med din väns hund, lär dig ett instrument, skriv en dikt, baka en kaka, ät en kaka, fixa en ny frisyr, hjula över gräsmattan, res jorden runt, läs en bok, designa ditt eget öde, skapa en religion, slå hål på en myt, bli egenföretagare, gå till ett startup-evenemang, samla in pengar, misslyckas snabbt, ta en tupplur, var lat, upprepa.",
        "TitleThisIsUs": "Det här är vi",
        "LinkedIn": "SignHero på LinkedIn",
        "CompanyName": "Avaintec",
        "CompanyAddress": "Kansakoulukuja 3, 00100 Helsinki",
        "CompanyWeb": "www.avaintec.com",
        "TitleMoreQuestion": "Har du frågor?",
        "SignHeroEmail": "hello@signhero.io",
        "ApiDocsLink": "API-dokumentation",
        "TermOfUseLink": "Användarvillkor",
        "PrivacyPolicyLink": "Sekretesspolicy",
        "CopyrightYear": "© " + new Date().getFullYear() + " ",
        "CompanyOyName": "Avaintec Oy",
        "RegulationNotice": "Elektroniska signaturer har samma juridiska status som handskrivna signaturer i USA och EU tack vare",
        "ESignAct": "ESIGN Act",
        "eIDASRegulations": "eIDAS regulations",
        "Docs": "Dokumentation",
        "SignIn": "Logga in",
        "TermsOfUse": "Användarvillkor",
        "ThisLocale": "SV",
        "WelcomeToSignHero": "Välkommen till SignHero. Var god och ange din e-postadress klicka \"Fortsätt.\". Du kommer att få ett e-postmeddelande som låter dig ange ditt första lösenord och logga in.",
        "ConfirmAccountInfo": "Kontrollera din e-post och följ instruktionerna för att skapa ditt lösenord och logga in.",
        "MustBeEmail": "Ange en e-postadress.",
        "MustBeValidFullName": "Ange ett giltigt namn.",
        "HyperlinksNotAllowed": "Hyperlänkar är inte tillåtna.",
        "MustBeUniqueEmail": "Den här e-postadressen har redan lagts till.",
        "UserSignedUp": "Välkommen till SignHero! Vi har skickat ett e-postmeddelande med instruktioner om hur du aktiverar ditt konto. Om du redan har ett konto kan du återställa ditt lösenord på inloggningssidan.",
        "locale!fi": "Suomi - FI",
        "locale!en": "English - EN",
        "locale!pl": "Polski - PL",
        "locale!de" : "Deutsch - DE",
        "locale!sv" : "Svenska - SV",
        "locale!es" : "Español - ES",
        "locale!fr" : "Français - FR",
        "locale!nl" : "Nederlands - NL",
        "locale!th" : "ภาษาไทย - TH",
        "SessionType!password": "Lösenord",
        "SessionType!auth_code": "Applikation",
        "YouHaveBeenSignedOut": "Du har blivit utloggad.",
        "Error!UserExists": "En användare med e-postadressen eller användarnamnet du angav finns redan. Om du har glömt ditt lösenord klickar du på \"Logga in\" och ändrar det från länken \"Återställ lösenord\".",
        "Save": "Spara",
        "Error!Unexpected": "Ett oväntat fel inträffade. Försök igen senare.",
        "Create": "Skapa",
        "Complete": "Arkiv",
        "Sign": "Signera",
        "Welcome": "Välkommen",
        "CompleteProcessInfo": "Du kan inte signera dokumentet/dokumenten eftersom denna signaturförfrågan har stängts. Du kanske redan har signerat dessa dokument eller så annullerades förfrågan av avsändaren.",
        "SignInHere": "Logga in",
        "ReasonTitle": "SANNOLIKA SKÄL",
        "Reason1": "Du har redan signerat detta dokument",
        "Reason2": "En av undertecknarna har avböjt att signera",
        "Reason3": "Inbjudan anullerades av avsändaren",
        "Reason4": "Inbjudan gick ut efter ett år",
        "Reason5": "Du angav en felskriven adress",
        "Reason6": "Du fick en påminnelse och den gamla länken upphörde automatiskt att gälla",
        "SeeYouSoon" : "Vi ses snart igen",
        "HowToOpenExpiredSession" : "För att öppna sessionen igen, klicka på länken i ditt mejl.",
        "ClearCookieWarning": "Om du använder en offentlig eller delad dator, kom ihåg att ta bort webläsarens kakor, historik och cacheminne.",
        "JustASec": "En sekund",
        "SorryUnexpectedError": "Tyvärr, sidan hittades inte!",
        "InvalidLoginUrl": "Länken du följde kan vara felaktig, eller så har inbjudan tagits bort.",
        "InvitationInvalidatedReason": "Länken du följde kan vara felaktig, eller så har inbjudan tagits bort.",
        "You": "du",
        "DownloadPageTitle": "Granska och ladda ner signerade dokument",
        "DownloadPageSignatureValidationFilesDesc" : "Ifall av behov, använd dessa filer för att visa att du hade för avsikt att signera.",
        "AuthInfo" : "Autentiseringsinformation",
        "DownloadLinkInfo" : "Den här länken förblir aktiv ett år efter att signeringsprocessen slutfördes.",
        "Download": "Ladda ner",
        "OpenAuditTrail": "Granska verifieringskedjan",
        "ProcessNotFound": "Signeringsprocessen hittades inte. Vänligen verifiera dess ID.",
        "SignatureEvents": "Signaturer",
        "ProcessStatus!COMPLETED": "Slutförd",
        "ProcessStatus!CANCELED": "Avböjd",
        "ProcessStatus!PENDING": "Väntar",
        "ProcessStatus!UNKNOWN": "Okänd",
        "SignatureProcessId": "ID",
        "SignatureProcess": "Signerinsprocess",
        "SignatureFlowCreated": "Skapad",
        "SignatureFlowStatus": "Status",
        "NumberOfDocuments": "Dokument",
        "SignedBy": "Signerad av",
        "SignTime": "Signeringstid",
        "AuthType": "Autentiseringstyp",
        "AuthMethod!verification_code": "E-mail verifikation",
        "AuthMethod!access_token": "SignHero konto",
        "AuthMethod!strong_auth": "Stark",
        "AuthMethod!otp_token": "Tredje part",
        "AuthMethod!otac": "E-post",
        "AuthMethod!signer_token": "E-post",
        "AuthMethod!on_site": "Personligen",
        "PrivacyPolicy": "Sekretesspolicy",
        "EnterYourDetailsBelow": "Ange dina uppgifter nedan.",
        "ThirdParty": "Tredje part",
        "SignUpButtonLabel": "Fortsätt",
        "NameAndSurname": "Ditt för- och efternamn",
        "Email": "E-post",
        "Company": "Organisation",
        "ConfirmAccount": "Fortsätt",
        "Confirm": "Bekräfta",
        "Cancel": "Avbryt",
        "ConfirmAccountTitle": "Ange lösenord för ditt <span class='elem-pink'>SignHero</span>-konto",
        "ResetPasswordTitle": "Återställ lösenordet för ditt <span class='elem-pink'>SignHero</span>-konto",
        "ResetPassword": "Ange nytt lösenord",
        "Reset": "Återställ",
        "Organization": "Organisation",
        "WelcomeMessage": "Logga in till dit <span class='elem-pink'>SignHero</span>-konto",
        "WelcomeText": "Ange dina uppgifter nedan.",
        "DontHaveSignHeroAccount": "Har du inget <span class='elem-pink'>SignHero</span>-konto?",
        "GetStarted": "Skapa ett",
        "AlreadyHaveSignHeroAccount": "Har du redan ett <span class='elem-pink'>SignHero</span>-konto?",
        "CreateYourSignHeroAccount": "Skapa ditt <span class='elem-pink'>SignHero</span>-konto",
        "AcceptTermsOfUseAndPrivacyPolicy": "Genom att klicka på Fortsätt accepterar du SignHeros <a class='terms-disclaimer-link' href='terms-of-use.html' target='_blank'>användarvillkor</a> och <a class='terms-disclaimer-link' href='privacy-policy.html' target='_blank'>sekretesspolicy</a>.",
        "YouFoundApi": "Du hittade SignHeros API!",
        "CheckApiDocumentation": "För att komma igång, kolla in vår senaste <a class=='elem-pink' href='${apiDocsLink}'>API-dokumentation</a>.",
        "AuditTrail" : "Verifieringskedja",
        "EnterProcessId" : "Ange signeringsprocess-ID, som visas i signaturrutan i varje dokument:",
        "SubmitID" : "Skicka",
        "NoProcessId" : "Var god och ange process-ID.",
        "ConfirmError1" : "Vänligen begär en ny inbjudan eller länk för återställning av lösenord från ",
        "ConfirmError2" : " sidan.",
        "SignHeroDotIo" : "SignHero.io",
        "ResetPWError" : "Den här länken kan ha upphört att gälla.<br>Återställ ditt lösenord på <a href='/#/signin' class='elem-pink'>inloggningssidan</a>.",
        "Error!tax_id_invalid" : "Ogiltigt skatte-ID.",
        "CreditCardSaved" : "Kreditkort har lagts till.",
        "BillingInformationSaved" : "Faktureringsinformation sparad.",
        "dateFormat" : "MMM dd, yyyy",
        "timeFormat" : "dd.MM, HH:mm",
        "Error!card_declined" : "Kreditkortet avvisades av betalningssystemet.",
        "Error!incorrect_cvc" : "Kreditkortet avvisades av betalningssystemet.",
        "Error!expired_card" : "Kreditkortet avvisades av betalningssystemet.",
        "Error!processing_error" : "Kreditkortet avvisades av betalningssystemet.",
        "Contact" :  "Kontakt",
        "name" : "Namn",
        "email" : "E-post",
        "LoginEmail" : "E-post",
        "EnterYourEmail" : "Vänligen ange din e-postadress:",
        "Password" : "Lösenord",
        "HelloThere" : "Hej där,",
        "ChangePassword" : "Byt lösenord",
        "CurrentPassword" : "Nuvarande lösenord",
        "NewPassword" : "Nytt lösenord",
        "RepeatPassword" : "Repetera lösenordet",
        "PasswordSet" : "Lösenord sparat.",
        "SignHeroSentPasswordResetRequest" : "Begäran om lösenordsåterställning skickas om du är en registrerad användare. Håll koll på din e-post!",
        "SignHeroSentConfirmTokenRequest" : "Ny kontobekräftelse begärd. Håll koll på din e-post!",
        "PP!MinLength" : "Minimum 10 tecken",
        "PP!MaxLength" : "Max 128 tecken",
        "PP!RepeatingChars" : "Inga trippeltecken",
        "PP!Lowercase" : "Några små bokstäver",
        "PP!Uppercase" : "Några stora bokstäver",
        "PP!Numbers" : "Några siffror",
        "PP!Specials" : "Några specialtecken",
        "PP!Passphrase" : "Lösenord (minst 20 tecken)",
        "PP!OrPassphrase" : "Lösenord (minst 20 tecken), eller",
        "Action!SetPassword" : "Välj lösenord",
        "Action!ConfirmAccount" : "Bekräfta konto",
        "TooltipSetPassword" : "Felaktig e-postadress eller så är lösenorder olika.",
        "Action!EditProfile" : "Redigera profil",
        "Action!SignOut" : "Logga ut",
        "Action!SignIn" : "Logga in",
        "Update" : "Uppdatera",
        "Error!PasswordChangeFailedInvalidPassword" : "Ditt gamla lösenord är felaktigt. Var god försök igen.",
        "Error!InvalidEmail" : "Vänligen ange din e-postadress.",
        "Error!InvalidPasswordReset" : "Ditt lösenordsåterställningstoken var ogiltigt eller har upphört att gälla. Begär en ny på länken \"Återställ lösenord\" och försök igen.",
        "Error!InvalidCredentials" : "Ogiltig e-postadress eller lösenord.",
        "Error!InvalidScopes" : "Dina rättigheter tillåter inte användning av denna applikation. Kontakta administratören.",
        "Error!TemporarilyLockedOut" : "Inloggning är tillfälligt blockerad på den här enheten på grund av upprepade fel. Om du har glömt ditt lösenord, återställ det från länken \"Glömt ditt lösenord?\". Inloggningen kommer bli tillgänglig igen om ${minutes} minuter.",
        "Error!TokenCheckFailed" : "Den här länken tycks ha upphört att gälla.<br>Återställ ditt lösenord på inloggningssidan.",
        "History" : "Vår historia",
        "Error!too_many_incorrect_email": "För många felaktiga e-postadresser, försök igen imorgon.",
        "PasswordRequirement" : "Ditt lösenord bör vara minst 10 tecken långt och innehålla en siffra, en stor bokstav, en liten bokstav och ett specialtecken. Alternativt kan du använda minst 15 tecken. Använd inte mer än två på varandra följande identiska tecken.",
        "EmailRequired" : "E-postadress krävs",
        "EmailMustValid" : "E-postadressen bör vara giltig",
        "PasswordMustValid" : "Lösenordet måste uppfylla komplexitetskraven",
        "RepeatPasswordMustSame" : "Lösenorden bör vara identiska",
        "Youtube" : "SignHero YouTube",
        "TitleNews" : "Nyheter",
        "ReadMoreLink" : "Läs mer",
        "OtherNewsBtn" : "Andra nyheter",
        "NewsListBg1" : "Det här har vi hållit på med",
        "NewsListBg2" : "Läs nyheter om våra bedrifter, releaser och omnämnanden i media.",
        "Share" : "Dela",
        "Locales": "Språk",
    },
    "client": {
        "ClientSecretInfo": "Förvara klienthemligheten säkert. Du kan ändra den men du kommer inte att kunna se den efter att du avslutat den här sessionen.",
        "Error!InvalidDomain": "Ogiltigt domännamn.",
        "DomainValidationDownloadInfo1": "För att validera din domän:",
        "DomainValidationDownloadInfo2": "Ladda ner följande fil:",
        "DomainValidationDownloadInfo3": "Ladda upp filen till din domäns rotkatalog:",
        "DomainValidationDownloadInfo4": "Kontrollera att filen kan nås från länken:",
        "DomainValidationDownloadInfo5": "kom tillbaka till den här sidan och klicka \"Valider domän\"",
        "LastClientSecretReset": "Senast ändrad",
        "FailedDomainValidation": "Validering av din domän misslyckades. Har du följt instruktionerna?",
        "OAuthApplications": "OAuth2 applikationer",
        "Application": "OAuth2 applikation",
        "IntegrateWithOAuth": "Integrera med OAuth2",
        "NewOAuthApplication": "Ny OAuth2 applikation",
        "ApplicationName": "Applikationsnamn *",
        "AddOAuthApplication": "Lägg till applikation",
        "AddUri": "Lägg till fler URIn",
        "CreationDate": "Skapat",
        "CreatedBy": "Skapat av",
        "AllowedGrantTypes": "Godkända typer av beviljanden",
        "OAuthRedirectURIs": "Omstyrnings URIer *",
        "OAuthClientsDomainURI": "Domän",
        "OAuthClientsDomainTitle": "Applikationsdomän *",
        "AppDescription": "Applikationsbeskrivning",
        "VerifyDomain": "Validera domän",
        "DomainVerified": "Domänen har validerats.",
        "WhyValidateDomain": "Du måste verifiera ägandet av din domän. Applikationen kan inte användas innan domänvalideringen har lyckats. Se <a class='paragraph-links' href='/docs/api' target='_blank'>API-dokumentationen</a> för detaljer.",
        "ClientSecret": "Klienthemlighet",
        "ClientId": "Klient-ID",
        "ClientCredentials": "Klientautentiseringsuppgifter",
        "VerifiedApp": "Aktiv",
        "UnverifiedApp": "Inaktiv",
        "ResetClientSecret": "Byt hemlighet",
        "ConfirmDeleteApiClient": "Är du säker på att du vill ta bort den här appen?\nFör att fortsätta, ange koden: ${rndCode}",
        "ConfirmResetClientSecret": "Är du säker på att du vill återställa klienthemligheten?\nFör att fortsätta, ange koden: ${rndCode}",
        "AppHasBeenDeleted": "Applikationen ${appName} har tagits bort.",
        "AppHasBeenCreated": "Applikationen ${appName} har skapats.",
        "YourChangesHaveBeenSaved": "Dina ändringar har sparats.",
        "ClientSecretChanged": "Klienthemligheten har ändrats.",
        "CheckApiDocs": "Du måste verifiera ägandet av din domän innan applikationen kan användas. Se <a href='/docs/api' target='_blank'>API-dokumentationen</a> för mer information."
    },
    "oauth2consent": {
        "AllowAccess": "Tillåt åtkomst",
        "OAuthAccessDescriptionHeader": "<span class='elem-pink'>${appName}</span> behöver din auktorisering innan du kan använda den för att:",
        "OAuthBasicPermissions": "  <li>Skicka signeringsinbjudningar och påminnelser</li>" +
                                 "  <li>Få åtkomst till, avbryta och radera signeringsinbjudningar som skickats via den</li>" +
                                 "  <li>Få åtkomst till signerade dokument som skickas för signering via den</li>",
        "OAuthSigningUrlPermissions": " <li>Ge dig åtkomst till att signera dokument som laddats upp med den</li>",
        "OAuthProfilePermissions": " <li>Få tillgång till ditt namn, e-post, företagsnamn, organisationsnamn, valt kommunikationsspråk " +
                                    "och se om du har administratörsrättigheter</li>",
        "OAuthAllDataPermissions": " <li>Få tillgång till, avbryta och radera alla dina signeringsinbjudningar</li>" +
                                   " <li>Få tillgång till alla dina dokument</li>",
        "OAuthAccessWarning": "Detta innebär också att du godkänner ${appName}s användarvillkor och sekretesspolicy.",
        "QuestionToAllowAccess": "Tillåt <span class='elem-pink'>${appName}</span> att komma åt ditt SignHero-konto " +
                                 "<span class='elem-bold'>${userEmail}</span>?<br><br>Om du ändrar dig " +
                                 "kan du alltid ändra dina SignHero-kontoinställningar.",
        "Cancel": "Avbryt",
        "Allow": "Tillåt",
        "NotFound": "Tyvärr, appen hittades inte",
        "OAuthResourceNotFound": "Tyvärr måste applikationen som ledde dig hit åtgärda problemet."
    }
}
